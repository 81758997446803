var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded)?_c('div',{staticClass:"mt-4 bg-white p-8"},[_c('h4',[_vm._v(_vm._s(_vm.$t('admin.challenges.translations.title')))]),_c('div',{staticClass:"w-1/2"},[_c('vs-select',{staticClass:"mt-4 w-full border-grey-light",attrs:{"label":_vm.$t('apps.registered-users.language'),"value":_vm.mainLanguage},on:{"input":_vm.mainLanguageChosen}},_vm._l((_vm.spotlioLanguages),function(language){return _c('vs-select-item',{key:language.acronym,attrs:{"value":language.id,"text":language.name}})}),1)],1),_c('div',[(_vm.challenge.main_language !== null && _vm.challenge.main_language !== undefined)?_c('LocalizedContent',{attrs:{"fixedLanguage":_vm.spotlioLanguages.find(function (lang) { return lang.id == _vm.mainLanguage; }).acronym,"fieldsDefinition":([
                { name: 'title', label: _vm.$t('admin.challenges.translations.form.title'), type: 'text', maxLength: 255 },
                { name: 'thumbnail_url', label: _vm.$t('admin.challenges.translations.form.thumbnail'), type: 'image', height: '350', validSizes: [{ width: '940', height: '625' }], validationMessage: 'woof' },
                { name: 'cover_image_url', label: _vm.$t('admin.challenges.translations.form.cover'), type: 'image', height: '350', validSizes: [{ width: '940', height: '625' }] },
                { name: 'cover_url', label: _vm.$t('admin.challenges.translations.form.cover_url'), type: 'text', maxLength: 500 },
                { name: 'prize', label: _vm.$t('admin.challenges.translations.form.prize'), type: 'text', maxLength: 255 },
                { name: 'place', label: _vm.$t('admin.challenges.translations.form.place'), type: 'text', maxLength: 255 },
                { name: 'short_description', label: _vm.$t('admin.challenges.translations.form.short_description'), type: 'textarea', maxLength: 100 },
                { name: 'description', label: _vm.$t('admin.challenges.translations.form.description'), type: 'rich_textarea', maxLength: 65535 } ])},model:{value:(_vm.translations),callback:function ($$v) {_vm.translations=$$v},expression:"translations"}}):_vm._e()],1),_c('div',{staticClass:"flex items-center ml-0 gap-4 mt-5"},[_c('vs-button',{staticClass:"rounded-lg xs:w-full sm:w-auto ml-2 ",on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('admin.challenges.translations.saveButton'))+" ")]),_c('vs-button',{staticClass:"rounded-lg xs:w-full sm:w-auto text-primary",attrs:{"type":"border"},on:{"click":function($event){return _vm.$router.push({ name: 'admin-challenges' })}}},[_vm._v(" "+_vm._s(_vm.$t('admin.challenges.translations.cancelButton'))+" ")])],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }