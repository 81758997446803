<template ref="template">
    <b-tabs ref="tabs" v-model="activeTab">
        <slot/>
    </b-tabs>
</template>
<script>
export default {
    name: 'SKStepper',
    props: {
        asTabs: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            activeTab: 0,
            slotStepComponentName: 'SKStepperStep',
            steps: [],
        }
    },
    mounted() {
        this.steps = this.findAllChildrenOfType(this)
        if (!this.asTabs) {
            this.disableNotActiveTabs()
        }
    },
    methods: {
        stepFinished(stepComponent) {
            const finshedIndex = this.steps.findIndex(child => child === stepComponent);
            if (finshedIndex < this.steps.length - 1) {
                this.steps[finshedIndex + 1].enable()
                this.$nextTick(() => {
                    this.activeTab = finshedIndex + 1
                });
            }
            if (finshedIndex === this.steps.length - 1) {
                this.$emit('onComplete')
            }
        },
        stepBack(stepComponent) {
            const currentIndex = this.steps.findIndex(child => child === stepComponent);
            if (currentIndex > 0) {
                this.$nextTick(() => {
                    this.activeTab = currentIndex - 1
                });
            }  
        },
        disableNotActiveTabs() {
            this.steps.forEach(step => step.disable())
            this.steps[0].enable()
        },
        findAllChildrenOfType(vm) {
            let matches = vm.$children.filter(child => child.$options.name === this.slotStepComponentName);
            for (let child of vm.$children) {
                matches = matches.concat(this.findAllChildrenOfType(child));
            }
            return matches;
            
        },
        getNthChildOfTypeRecursive(index) {
            const allChildrenOfType = this.findAllChildrenOfType(this);
            return allChildrenOfType[index] || null;
        },
        getLastStep() {
            return this.steps[this.steps.length - 1] || null;
        },
        getFirstStep() {
            return this.steps[0] || null;
        },
    }
}
</script>