import store from '@/modules/Shared/Store/store'
import CampaignsService from "@/modules/Apps/Services/CampaignsService";
import {fromDBToTimezone} from "@/modules/Shared/Helpers/dateHelper";
import {urlToBase64} from "@/modules/Shared/Helpers/fileHelper";
import AppsService from "@/modules/Apps/Services/AppsService";
import router from "@/router";

export default {
  async setApps({commit}, appsUuids) {
    const apps = await Promise.all(appsUuids.map(appUuid => AppsService.basicAppInfo(appUuid)))
    commit('SET_APPS', apps.map(app => app.data.object))
  },
  selectCampaignInstallations({commit}, installations) {
    commit('SET_CAMPAIGN_INSTALLATIONS', installations)
    commit('SET_CAMPAIGN_LANGUAGE_INSTALLATIONS_COUNT', {acronym: null, count: installations.length})
  },

  removeCampaignInstallation({commit}, installationUuid) {
    const installations = store.getters["apps/campaign_selected_installations"].filter(installation => installation.uuid !== installationUuid)
    commit('SET_CAMPAIGN_INSTALLATIONS', installations)
    commit('SET_CAMPAIGN_LANGUAGE_INSTALLATIONS_COUNT', {acronym: null, count: installations.length})
  },

  clearCampaignInstallations({commit}) {
    commit('SET_CAMPAIGN_INSTALLATIONS', [])
    commit('SET_CAMPAIGN_LANGUAGE_INSTALLATIONS_COUNT', {acronym: null, count: 0})
  },

  enableCampaignSelectUsers({commit, state}) {
    commit('SET_CAMPAIGN_SELECT_USERS', true)

    const content = store.getters["apps/campaign_languages_is_default_selected"]
      ? store.getters["apps/campaign_default_language_content"]
      : store.getters["apps/campaign_contents"].length > 0
        ? store.getters["apps/campaign_contents"][0]
        : store.getters["apps/campaign_base_content"]

    commit('SET_CAMPAIGN_REGISTRATION', state.campaigns.form_options.registration[0])
    commit('SET_CAMPAIGN_FAVORITE_RESORT', null)
    commit('SET_CAMPAIGN_LANGUAGES', [])
    commit('SET_CAMPAIGN_CONTENT', [{
      ...content,
      count: 0,
      language: null
    }])
  },

  async disableCampaignSelectUsers({commit}) {
    commit('SET_CAMPAIGN_SELECT_USERS', false)
    commit('SET_CAMPAIGN_INSTALLATIONS', [])
    commit('SET_CAMPAIGN_CONTENT', [])
  },

  async addCampaignLanguageContent({commit, dispatch, getters}, {language, content}) {
    const acronym = language ? language.acronym : null
    commit('ADD_CAMPAIGN_LANGUAGE_CONTENT', content || {...getters.campaign_base_content, language})
    const count = await dispatch('countLanguageContentInstallations', {acronym})
    commit('SET_CAMPAIGN_LANGUAGE_INSTALLATIONS_COUNT', {acronym , count})
  },

  async countLanguageContentInstallations({getters}, {acronym}) {
    const options = getters.campaign_content_selection_options(acronym)
    return await CampaignsService.countInstallations(options)
  },

  async recalculateAllContentInstallations({commit, getters}) {
    for await (const content of getters.campaign_contents) {
      const acronym = content.language ? content.language.acronym : null
      const options = getters.campaign_content_selection_options(acronym)
      const count = await CampaignsService.countInstallations(options)
      commit('SET_CAMPAIGN_LANGUAGE_INSTALLATIONS_COUNT', {acronym, count})
    }
  },

  removeCampaignLanguageContent({commit}, acronym) {
    commit('REMOVE_CAMPAIGN_LANGUAGE_CONTENT', acronym)
  },

  setCampaignContentField({commit, getters}, {acronym, fieldName, fieldValue}) {
    let contents = [];

    if (!acronym) {
      let content = getters.campaign_language_content(null)
      content[fieldName] = fieldValue
      commit('SET_CAMPAIGN_CONTENT', [content])
      return
    }

    const defaultLanguageContent = getters.campaign_default_language_content

    if(defaultLanguageContent && acronym === defaultLanguageContent.language.acronym) {
      contents = getters.campaign_contents.map(c => {
        return c.use_default_language_content || c.language.acronym === acronym
          ? {
            ...defaultLanguageContent,
            [fieldName]: fieldValue,
            language: c.language,
            use_default_language_content: c.use_default_language_content,
            count: c.count,
          } : c
      })
      commit('SET_CAMPAIGN_CONTENT', contents)
      return;
    }

    if (fieldName === 'use_default_language_content') {
      const languageContent = getters.campaign_language_content(acronym)
      const newLanguageContent = fieldValue
        ? {
          ...defaultLanguageContent,
          language: languageContent.language,
          count: languageContent.count,
          use_default_language_content: true
        } : {
          ...getters.campaign_base_content,
          count: languageContent.count,
          language: languageContent.language,
        }

      contents = getters.campaign_contents.map(c => {
        return c.language.acronym === acronym ? newLanguageContent : c
      })

      commit('SET_CAMPAIGN_CONTENT', contents)
      return;
    }

    contents = getters.campaign_contents.map(c => {
      return c.language.acronym === acronym ? {
        ...c,
        [fieldName]: fieldValue
      } : c
    })

    commit('SET_CAMPAIGN_CONTENT', contents)
  },

  addUrlToCampaignContents({commit, getters}) {
    const contents = getters.campaign_contents.map(c => {
      return {
        ...c,
        url: ''
      }
    })
    commit('SET_CAMPAIGN_CONTENT', contents)
  },

  removeUrlFromCampaignContents({commit, getters}) {
    const contents = getters.campaign_contents.map(({url, ...rest}) => { // eslint-disable-line
      return rest;
    });

    commit('SET_CAMPAIGN_CONTENT', contents)
  },


  async campaignToEditForm({dispatch}, campaign) {
    dispatch('campaignToForm', {campaign, isEdit: true})
  },

  async campaignToCloneForm({dispatch}, campaign) {
    console.log(campaign)
    dispatch('campaignToForm', {campaign, isEdit: false})
  },

  async campaignToForm({commit, state, getters, dispatch}, {campaign, isEdit = true}) {
    const formOptions = state.campaigns.form_options

    const options = campaign.options
    const challenge = campaign.challenge
    const content = campaign.content

    let language = null;
    let registrationOption;
    let ctaOption;

    commit('RESET_CAMPAIGN')
    commit('SET_CAMPAIGN_EDIT_MODE', isEdit)

    if (options.installation_uuids) {
      commit('SET_CAMPAIGN_SELECT_USERS', true)
      const installations = await CampaignsService.getInstallations([
        {
          field: 'installation_uuids',
          value: options.installation_uuids.join(';')
        },
        {
          field: 'all_user_installations',
          value: true
        }
      ])

      commit('SET_CAMPAIGN_INSTALLATIONS', installations.data.filter(i => i.user).map(installation => ({
        uuid: installation.uuid,
        username: installation.user.username,
        device: installation.device.model
      })))
    } else {
      // Language
      if (options.language) {
        language = getters.campaign_form_languages.find(language => language.acronym === options.language)
        commit('SET_CAMPAIGN_LANGUAGES', [language])
      }

      // Registration
      if (options.only_registered) {
        registrationOption = formOptions.registration.find(option => option.code === 'only_registered')
      } else if (options.only_unregistered) {
        registrationOption = formOptions.registration.find(option => option.code === 'only_unregistered')
      } else if (options.favorite_resort_uuid) {
        registrationOption = formOptions.registration.find(option => option.code === 'favorite_resort')
        const resorts = await AppsService.getResorts(router.currentRoute.params.uuid)
        const resort = resorts.find(resort => resort.uuid === options.favorite_resort_uuid)
        commit('SET_CAMPAIGN_FAVORITE_RESORT', { code: resort.uuid, label: resort.name })
      } else {
        registrationOption = formOptions.registration.find(option => option.code === 'all')
      }
      commit('SET_CAMPAIGN_REGISTRATION', registrationOption)
    }

    // CTA
    if (challenge && challenge.uuid) {
      ctaOption = formOptions.cta.find(option => option.code === 'challenge')
      commit('SET_CAMPAIGN_CHALLENGE', { code: challenge.uuid, label: challenge.name })
    } else if (content.url) {
      ctaOption = formOptions.cta.find(option => option.code === 'url')
    } else {
      ctaOption = formOptions.cta.find(option => option.code === 'none')
    }
    commit('SET_CAMPAIGN_CTA', ctaOption)

    if (campaign.category) {
      commit('SET_CAMPAIGN_CATEGORY', campaign.category.uuid)
    }

    // Content
    dispatch('addCampaignLanguageContent', {
      language,
      content: {
        ...getters.campaign_base_content,
        is_valid: true,
        language,
        title: content.title,
        message: content.message,
        ...(content.image && {image: {
            url: content.image,
            content: await urlToBase64(content.image)
          }
        }),
        ...(ctaOption.code === 'url' && {url: content.url})
      }
    })

    // Schedule
    if (isEdit) {
      commit('SET_CAMPAIGN_SEND_NOW', false)
      commit('SET_CAMPAIGN_SCHEDULE_DATE', fromDBToTimezone(campaign.to_be_delivered_at, store.getters.AppActiveUser.timezone).toString())
    } else {
      commit('SET_CAMPAIGN_SEND_NOW', true)
    }

    // TTL
    commit('SET_CAMPAIGN_TTL', campaign.ttl)

    if (state.campaigns.form_options.ttl.map(option => option.code).includes(campaign.ttl)) {
      commit('SET_CAMPAIGN_TTL_OPTION', state.campaigns.form_options.ttl.find(option => option.code === campaign.ttl))
    } else {
      commit('SET_CAMPAIGN_TTL_OPTION', state.campaigns.form_options.ttl.find(option => option.code === 'custom'))
    }

  },

  resetStatistics({commit}) {
    commit('RESET_STATISTICS')
  }
}
