<template>
    <thead class="vs-table-thead">
        <tr class="table-header">
            <th v-if="draggable" class="w-20 ml-1">{{draggableHeadTitle}}</th>
            <th v-for="(head, index) in headList" :key="index" :class="head.class ? head.class : ''">
                <div class="vs-table-text font-bold uppercase" @click="sort(head)" >
                    {{ head.title }}
                    <span v-if="head.sortable && sortedBy.value && sortedBy.field === head.field" class="sort-th"><i class="vs-icon notranslate icon-scale th-sort-icon material-icons null">{{ sortedBy.value === 'asc'?'expand_less':'expand_more' }}</i></span>
                </div>
            </th>
        </tr>
    </thead>
</template>
<script>
export default {
    name: 'data-table-header',
    props: {
        draggable: {type:Boolean, default: false},
        draggableHeadTitle: {type:String, default: ''},
        headList: Array,
    },
    data(){
        return {
        sortedBy:{field:'',value:''}
        }
    },

    methods: {
        sort(head) {
        if(head.sortable){
            this.changeSortIcons(head.field);
            this.$emit('sort', this.sortedBy)
        }
        },
        changeSortIcons(field) {
            if(this.sortedBy.field === field){
                this.sortedBy.value = !this.sortedBy.value? 'asc': this.sortedBy.value=== 'asc' ? 'desc' : false;
            }else{
                this.sortedBy = {field,value:'asc'}
            }
        },
    }
}
</script>
<style scoped>
.table-header {
    background-color: #f3f2f7;
}
.vs-table--tbody-table .tr-values td, .vs-table-thead th {
    padding: 5px;
}
</style>
