<template>
    <div class="flex gap-8 ml-0 flex-column w-full md:w-2/3 lg:w-1/3">
        <div v-for="module in modules" :key="module.name">
            <vs-checkbox
                class="mb-4 text-base"
                v-model="module.enabled"
                @change="handleModuleEnabledChange(module)"
            >
                <b class="ml-1">{{ $t('admin.apps.modules.' + module.name + '.name') }}</b>
            </vs-checkbox>

            <div v-if="module.configuration && module.enabled" class="ml-8">
                <div v-for="field in module.configuration" :key="field.key">
                    <vs-input
                        v-if="typeof field.value === 'string' && !module.hiddenFields.includes(field.key)"
                        class="mb-2 border-grey-light w-full"
                        type="text"
                        v-model="field.value"
                        :label="$t('admin.apps.modules.' + module.name + '.' + field.key)"
                        :danger="module.enabled && !field.value"
                    />

                    <vs-checkbox v-if="typeof field.value === 'boolean' && !module.hiddenFields.includes(field.key)"
                                 class="ml-0 mb-4 mt-4 text-dark"
                                 v-model="field.value"
                    >
                        {{ $t('admin.apps.modules.' + module.name + '.' + field.key) }}
                    </vs-checkbox>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import ApplicationService from "@/modules/Admin/Services/ApplicationService";
import loader from "@/modules/Shared/Mixins/loader";

export default {
    name: 'ModuleSettings',
    mixins: [loader],
    data() {
        return {
            modules: [],
            initialModuleNames: [],
        }
    },
    async created() {
        await this.load(async () => {
            let modules = []
            try {
                modules = await ApplicationService.getApplicationModules(this.$route.params.uuid)
            } catch ($ex) {
                console.log('No modules', $ex)
            }

            this.modules = this.fromAPIToForm(modules)
            this.initialModuleNames = this.modules.filter(m => m.enabled).map(m => m.name)
        })
    },
    methods: {
        save() {
            return new Promise(async (resolve, reject) => {
                this.loadAndNotifyError(async () => {
                    const applicationUuid = this.$route.params.uuid
                    const modules = this.fromFormToAPI(this.modules)

                    const promises = modules.map(module => {
                        if (this.initialModuleNames.includes(module.name)) {
                            return module.enabled
                                ? ApplicationService.editApplicationModule(applicationUuid, module.id, {configuration: module.configuration})
                                : ApplicationService.deleteApplicationModule(applicationUuid, module.id)
                        }
                        if (module.enabled) {
                            return ApplicationService.createApplicationModule(applicationUuid, module.id, { configuration: module.configuration })
                        }
                    })

                    return Promise.all(promises)
                }).then((result) => {
                    if (!result) {
                        reject()
                        return;
                    }
                    resolve()
                })
            })
        },

        fromAPIToForm(modules) {
            const moduleNames = modules.map(module => module.type.name !== 'etickets' ? module.type.name : `${module.type.name}_${module.configuration.find(m => m.key === 'sdk').value}`)

            return this.emptyModules().map(emptyModule => {
                const index = moduleNames.findIndex(name => emptyModule.name === name)
                return index === -1
                    ? emptyModule
                    : {
                        ...emptyModule,
                        enabled: true,
                        configuration: modules[index].configuration
                    }
            })
        },
        fromFormToAPI(modules) {
            return modules.map(module => {
                return {
                    ...module,
                    configuration: module.configuration === null ? null : JSON.stringify(module.configuration)
                }
            })
        },
        handleModuleEnabledChange(module) {
            if (!module.enabled) {
                module.configuration = this.emptyModules()
                    .find(m => m.name === module.name)
                    .configuration
                
            }
        },
        emptyModules() {
            return [
                {
                    id: 1,
                    name: 'salesforce',
                    enabled: false,
                    hiddenFields: [],
                    configuration: [
                        { key: 'sender_id', value : '', },
                        { key: 'mid_salesforce', value : '', },
                        { key: 'appid_salesforce', value : '', },
                        { key: 'contact_key_type', value : '', },
                        { key: 'inbox_salesforce', value : false, },
                        { key: 'app_salesforce_name', value : '', },
                        { key: 'location_salesforce', value : false, },
                        { key: 'proximity_salesforce', value : false, },
                        { key: 'accesstoken_salesforce', value : '', },
                        { key: 'etanalytics_salesforce', value : false, },
                        { key: 'pianalytics_salesforce', value : false, },
                        { key: 'marketing_cloud_server_url_salesforce', value : '', },
                    ],
                },
                {
                    id: 3,
                    name: 'etickets_skidata',
                    enabled: false,
                    hiddenFields: ['sdk'],
                    configuration: [
                        { key: 'sdk', value: 'skidata', },
                        { key: 'ios', value: '', },
                        { key: 'android', value: '', },
                    ]
                },
                {
                    id: 3,
                    name: 'etickets_axess',
                    enabled: false,
                    hiddenFields: ['sdk'],
                    configuration: [
                        { key: 'sdk', value: 'axess', },
                        { key: 'api_url', value: '', },
                        { key: 'client_id', value: '', },
                        { key: 'client_secret', value: '', },
                    ]
                },
                {
                    id: 2,
                    name: 'arlocation',
                    enabled: false,
                    hiddenFields: [],
                    configuration: null
                },
                {
                    id: 4,
                    name: 'watch',
                    enabled: false,
                    hiddenFields: [],
                    configuration: null
                }
            ]
        }
    }
}
</script>
