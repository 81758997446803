<template>
    <div>
        <div class="vx-card p-8 w-full">
            <PreviewPhotoModal
                ref="previewPhotoModal"
                :image="previewImage"
            />

            <h4 class="mb-6">{{ $t('admin.photos.filters') }}</h4>
            <div class="flex flex-col md:flex-row gap-8">
                <div class="w-full md:w-1/2">
                    <h5>{{ $t('admin.photos.origin') }}</h5>
                    <Combobox
                        v-model="filters.country_id"
                        class="my-4"
                        :multiple="false"
                        :options="countries.map(c => ({ key: c.id, text: c.name }))"
                        :label="$t('admin.photos.countries')"
                    />

                    <Combobox
                        v-model="filters.application_id"
                        class="my-4"
                        :multiple="false"
                        :options="apps.map(app => ({ key: app.id, text: app.name }))"
                        :label="$t('admin.photos.applications')"
                    />

                    <Combobox
                        v-model="filters.resort_id"
                        class="my-4"
                        :multiple="false"
                        :options="resorts.map(r => ({ key: r.id, text: r.name }))"
                        :asyncFirstFetchCallback="fetchNewResorts"
                        :asyncNextFetchCallback="fetchNextResorts"
                        :label="$t('admin.photos.resorts')"
                    />
                </div>

                <div class="w-full md:w-1/2">
                    <h5 class="mb-4">{{ $t('admin.photos.content') }}</h5>

                    <div class="flex flex-row gap-4 mb-4">
                        <DatePicker
                            class="w-1/2"
                            :label="$t('admin.photos.start_date')"
                            v-model="startDateObject"
                        />
                        <DatePicker
                            class="w-1/2"
                            :label="$t('admin.photos.end_date')"
                            v-model="endDateObject"
                            :min-date="startDateObject.plus({days: 1})"
                        />
                    </div>

                    <vs-select :label="$t('admin.photos.status')" v-model="filters.published" class="w-full mb-4">
                        <vs-select-item :value="null" text="Select option" />
                        <vs-select-item v-for="item in publishedOptions" :key="item.key" :value="item.key" :text="item.label"  />
                    </vs-select>

                    <vs-select :label="$t('admin.photos.privacity')" v-model="filters.private" class="w-full">
                        <vs-select-item :value="null" text="Select option" />
                        <vs-select-item v-for="item in privacityOptions" :key="item.key" :value="item.key" :text="item.label"  />
                    </vs-select>
                </div>
            </div>

            <div class="flex items-center ml-0 gap-4 mt-5">
                <vs-button class="rounded-lg xs:w-full sm:w-auto ml-2 " @click="handleFilter">
                    {{ $t('admin.photos.filter') }}
                </vs-button>
                <vs-button type="border" class="rounded-lg xs:w-full sm:w-auto text-primary" @click="handleReset">
                    {{ $t('admin.photos.reset') }}
                </vs-button>
            </div>
        </div>

        <div>
            <h4 class="mt-6 mb-4">{{ $t('admin.photos.title') }}</h4>
            <div v-if ="photos.length > 0" class="flex flex-row flex-wrap gap-4">
                <PhotoCard
                    v-for="photo in photos"
                    :key="photo.id"
                    :thumbnail="photo.url"
                    :username="photo.owner.username"
                    :is-public="photo.isPublic"
                    :is-published="photo.published"
                    :application="photo.application.name"
                    :resort="photo.resort.name"
                    @preview="() => handlePhotoPreview(photo.url)"
                    @publishChange="() => handlePhotoPublishChange(photo)"
                />
            </div>
            <p v-else>
                {{ $t('admin.photos.no_photos') }}
            </p>
            <vs-pagination
                v-if="pagination.total_pages > 1"
                class="mt-base"
                :total="pagination.total_pages"
                :value="pagination.current_page"
                @change="handlePageChange"
            />
        </div>
    </div>

</template>

<script>

import loader from "@/modules/Shared/Mixins/loader";
import PaginationService from "@/modules/Shared/Services/PaginationService";
import DataTable from '@/modules/Shared/Components/table/SKDataTable.vue'
import DatatablePageLayout from "@/modules/Shared/Pages/DatatablePageLayout.vue";
import CountryService from "@/modules/Shared/Services/CountryService";
import Combobox from "@/modules/Shared/Components/Combobox.vue";
import ResortService from "@/modules/Apps/Services/ResortService";
import {unifyPaginatedResponse} from "@/modules/Shared/Helpers/apiResponseHelper";
import ApplicationService from "@/modules/Shared/Services/ApplicationService";
import DatePicker from "@/modules/Shared/Components/form/DatePicker.vue";
import {DateTime} from "luxon";
import PhotoService from "@/modules/Admin/Services/PhotoService";
import PhotoCard from "@/modules/Admin/Components/Photos/PhotoCard.vue";
import PreviewPhotoModal from "@/modules/Admin/Components/Photos/PreviewPhotoModal.vue";

export default {
    name: "PhotosPage",
    components: {PreviewPhotoModal, PhotoCard, DatePicker, Combobox, DataTable, DatatablePageLayout},
    mixins: [loader],
    data() {
        return {
            countries: [],
            apps: [],

            resorts: [],
            resortComboboxPage: 1,
            resortComboboxEnded: false,

            photos: [],
            previewImage: null,

            filters: {
                resort_id: null,
                application_id: null,
                country_id: null,
                published: null,
                private: null,
                date: DateTime.now().minus({days: 7}).toFormat('yyyy-MM-dd') + ';' + DateTime.now().toFormat('yyyy-MM-dd'),
            },

            pagination: {
                total_pages: 1,
                current_page: 1
            },

            publishedOptions: [
                { key: 1, label: this.$t('admin.photos.published') },
                { key: 0, label: this.$t('admin.photos.unpublished') }
            ],

            privacityOptions: [
                { key: 2, label: this.$t('admin.photos.public') },
                { key: 0, label: this.$t('admin.photos.private') }
            ]
        }
    },
    async created() {
        this.filters = this.defaultFilters();
        await this.fetchPhotos()

        await this.loadAndNotifyError(async () => {
            [this.countries, this.apps] = await Promise.all([
                unifyPaginatedResponse(CountryService.getCountries, {per_page: 30}),
                unifyPaginatedResponse(ApplicationService.getApplications, {per_page: 30}),
            ])
        })
    },
    computed: {
        startDateObject: {
            get() { return this.filters.date ? DateTime.fromSQL(this.filters.date.split(';')[0]) : null },
            set(val) {
                let endDate = DateTime.fromSQL(this.filters.date.split(';')[1])

                if (endDate.startOf('day') <= val.startOf('day')) {
                    endDate = endDate.plus({ days: 1 })
                }

                this.filters.date = [val.toFormat('yyyy-MM-dd'), endDate.toFormat('yyyy-MM-dd')].join(';')
            }
        },
        endDateObject: {
            get() { return this.filters.date ? DateTime.fromSQL(this.filters.date.split(';')[1]) : null },
            set(val) {
                const startDate = this.filters.date.split(';')[0]
                this.filters.date = [startDate, val.toFormat('yyyy-MM-dd')].join(';')
            }
        },
    },
    methods: {
        async handlePhotoPublishChange(photo) {
            await this.loadAndNotifyError(async () => {
                const params = {
                    published: !photo.published
                }

                await PhotoService.editPhoto(photo.uuid, params)
                photo.published = !photo.published
            })
        },
        handlePhotoPreview(image) {
            this.previewImage = image
            this.$refs.previewPhotoModal.open()
        },
        async handlePageChange(page) {
            if (this.pagination.current_page !== page) {
                await this.fetchPhotos(page)
            }
        },
        async handleFilter() {
            await this.fetchPhotos()
        },
        handleReset() {
            this.filters = this.defaultFilters();
        },
        async fetchPhotos(page = 1) {
            return this.loadAndNotifyError(async () => {
                const params = PaginationService.parseParameters({
                    filters: Object.entries(this.filters).map(([key, value]) => ({
                        field: key,
                        value: value
                    })).filter(filter => filter.value !== null),
                })

                const photosResponse = await PhotoService.getPhotos(params, page)

                this.pagination = {
                    total_pages: Math.ceil(photosResponse.total / photosResponse.per_page),
                    current_page: photosResponse.current_page
                }

                this.photos = photosResponse.data
            })
        },
        defaultFilters() {
            return {
                resort_id: null,
                application_id: null,
                country_id: null,
                published: null,
                private: null,
                date: DateTime.now().minus({days: 7}).toFormat('yyyy-MM-dd') + ';' + DateTime.now().toFormat('yyyy-MM-dd'),
            }
        },
        async fetchResorts(filter, page = 1) {
            const response = await ResortService.getResorts(
                filter ? PaginationService.parseParameters({
                    filters: [ { field: 'name', value: filter } ],
                    per_page: 30,
                }) : {per_page: 30},
                page
            )

            if (response.data.length < 30) {
                this.resortComboboxEnded = true
            }

            this.resorts = [...this.resorts, ...response.data]
        },
        fetchNextResorts(filter) {
            if (this.resortComboboxEnded) {
                return
            }
            this.resortComboboxPage += 1
            return this.fetchResorts(filter, this.resortComboboxPage)
        },
        fetchNewResorts(filter) {
            this.resorts = []
            this.resortComboboxPage = 1
            return this.fetchResorts(filter, this.resortComboboxPage)
        },
    }
};
</script>
