<template>
    <div>
        <GeophotoChallengeGallery
            v-if="isGeophotoChallenge && geophotoGallery.loaded"
            :end-date="geophotoGallery.endDate"
            :start-date="geophotoGallery.startDate"
            :user-ids="geophotoGallery.userIds"
            :application-ids="geophotoGallery.applicationIds"
            :resort-ids="geophotoGallery.resortIds"
        />
        <div v-else class="w-full md:w-3/4 lg:w-3/4 xl:w-3/4 mx-auto">
            <div v-if="ranking && rankings.length > 0" class="grid grid-cols-1 lg:grid-cols-4 items-center gap-4">
                <ChallengeStanding
                    v-for="(ranking, index) in rankings"
                    :key="index"
                    :is-ranked="isRanked"
                    :rank="index + 1"
                    :value="ranking.value"
                    :value2="ranking.value2 ? ranking.value2 : null"
                    :thumbnail="ranking.avatar"
                    :username="ranking.username"
                />
            </div>

            <p v-else class="text-center">{{ $t('admin.challenges.ranking.no_ranking') }}</p>
        </div>
    </div>

</template>

<script>

import ChallengeService from '../Services/ChallengeService';
import loader from "@/modules/Shared/Mixins/loader";
import store from "@/modules/Shared/Store/store";
import {mapGetters} from "vuex";
import ChallengeStanding from "@/modules/Admin/Components/Challenges/ChallengeStanding.vue";
import GeophotoChallengeGallery from "@/modules/Admin/Components/Challenges/GeophotoChallengeGallery.vue";

export default {
    name: "ChallengeRankingPage",
    components: {GeophotoChallengeGallery, ChallengeStanding},
    mixins: [loader],
    data() {
        return {
            challenge: null,
            ranking: null,
            geophotoGallery: {
                startDate: null,
                endDate: null,
                userIds: [],
                applicationIds: [],
                resortIds: [],
                loaded: false,
            }
        }
    },
    computed: {
        ...mapGetters(['AppActiveUser']),
        isRanked() {
            return this.ranking ? this.ranking.showorder !== 0 : false
        },
        rankings() {
            return this.ranking ? this.ranking.rankings[0].ranking : []
        },
        isGeophotoChallenge() {
            return this.challenge ? this.challenge.type_id === 4 : false
        }
    },
    async beforeRouteEnter(to, from, next) {
        let challenge = await ChallengeService.getChallenge(to.params.uuid)
        await store.dispatch('setRouteElement', challenge)
        next(vm => vm.challenge = challenge)
    },

    async mounted() {
        await this.loadAndNotifyError(async () => {
            if (this.isGeophotoChallenge) {
                this.geophotoGallery = {
                    startDate: this.challenge.publish_start_date,
                    endDate: this.challenge.publish_end_date,
                    userIds: (await ChallengeService.getChallengeUsers(this.$route.params.uuid)).joined_user_ids,
                    applicationIds: this.challenge.application_ids,
                    resortIds: this.challenge.resort_ids,
                    loaded: true
                }
            } else {
                const {language, timezone} = this.AppActiveUser
                this.ranking = await ChallengeService.getChallengeRanking(this.$route.params.uuid, language, this.$i18n.locale, timezone, 'metric')
            }
        })
    },
};
</script>
