<template>
    <div v-if="internalItem">
        <ModalMenuItemGallery
            ref="modalMenuItemGallery"
            :images="resources"
            :selected="internalItem.icon"
            @select="handleSelectIcon"
        />
        <div>
            <h3 v-if="action === 'create'">{{ $t('apps.configurator.manage-item.title') }}</h3>
            <h3 v-else>{{ internalItem.titles[$store.state.AppActiveUser.language] }}</h3>
        </div>
        <div v-if="template && (template.section !== 'banners' && !template.section.includes('widget'))">
            <LabelForm>{{ $t('apps.configurator.manage-item.icon') }}</LabelForm>
            <div class="flex flex-row items-center gap-4">
                <vs-input type="text" maxlength="50"
                          class="input-modal w-50 border-grey-light"
                          v-model="internalItem.icon"
                          disabled
                />
                <div v-if="!readOnly" class="flex flex-row items-center gap-2">
                    <button class="sk-btn sk-btn-small sk-btn-primary rounded-lg btn-border p-2" @click="openGallery">
                        <feather-icon icon="ImageIcon" svgClasses="h-6 w-6 stroke-current text-primary" class="align-middle"/>
                    </button>
                    <button class="sk-btn sk-btn-small sk-btn-danger rounded-lg btn-border p-2" @click="clearIcon">
                        <feather-icon icon="XIcon" svgClasses="h-6 w-6 stroke-current text-danger" class="align-middle"/>
                    </button>
                </div>

            </div>

            <div v-if="imagesObject">
                <Alert
                    v-if="imagesObject.multiLanguage && imagesObject.missingImages.length > 0"
                    type="warning"
                    class="mt-4"
                >
                    <p class="font-medium mb-2">{{ $t('apps.configurator.preview.image_missing') }}:</p>
                    <ul>
                        <li v-for="image in imagesObject.missingImages" :key="image">{{ image }}</li>
                    </ul>
                </Alert>

                <Gallery :asset-list="imagesObject.images" />
            </div>
        </div>
        <div>
            <LabelForm>{{ $t('apps.configurator.manage-item.flag') }}</LabelForm>
            <vs-input :disabled="isDisabled || readOnly" type="text" maxlength="50"
                      class="input-modal w-full border-grey-light" v-model="internalItem.flag" />
        </div>
        <div>
            <LabelForm>{{ $t('apps.configurator.manage-item.screen-name') }}</LabelForm>
            <vs-input :disabled="isDisabled || readOnly" type="text" maxlength="50"
                      class="input-modal w-full border-grey-light" v-model="internalItem.screen_name" />
        </div>
        <div class="flex flex-row flex-wrap">
            <div class="w-50 ">
                <LabelForm>{{ $t('apps.configurator.manage-item.published') }}</LabelForm>
                <select :disabled="readOnly" class="w-full border-grey-light input-modal" v-model="internalItem.published">
                    <option :key="publishedOpt.value" :value="publishedOpt.value" v-for="publishedOpt in publishedOpts">
                        {{ publishedOpt.label }}
                    </option>
                </select>
            </div>
        </div>
        <div class="w-50">
            <LabelForm>{{ $t('apps.configurator.manage-item.width') }}</LabelForm>
            <vs-input :disabled="menu.type === 'tab' || readOnly" type="number" min="1" class="input-modal w-full border-grey-light" v-model="internalItem.width" />
        </div>
        <div class="hidden">
            <LabelForm>{{ $t('apps.configurator.manage-item.section') }}</LabelForm>
            <vs-input :disabled="true" type="text" maxlength="50" class="w-full border-grey-light input-modal"
                      v-model="internalItem.section" />
        </div>
        <div>
            <LabelForm>{{ $t('apps.configurator.manage-item.item-template') }}</LabelForm>
            <select :disabled="readOnly || this.internalItem.submenu_items.length > 0" class="w-full border-grey-light input-modal" v-model="internalItem.item_template_uuid"
                    @change="changeTemplate">
                <option :key="templatesOpt.value" :value="templatesOpt.value" v-for="templatesOpt in templatesOpts">
                    {{ templatesOpt.label }}
                </option>
            </select>
            <Alert v-if="!readOnly && this.internalItem.submenu_items.length > 0" type="warning" class="mt-4">
                {{ $t('apps.configurator.manage-item.cannot_change_template') }}
            </Alert>
        </div>
        <div v-if="showConfiguration">
            <div class="mt-10">
                <LabelForm>{{ $t('apps.configurator.manage-item.configuration-templates-title') }}</LabelForm>
            </div>
            <DynamicForm
                v-model="configurationValue"
                @input="getDinamicInputsConfiguration"
                :languages="languages.map(lang => lang.acronym)"
                :columns=1
                :isReadOnly="readOnly"
            />
        </div>
        <div class="languages-inputs">
            <div class="mt-3">
                <LabelForm>{{ $t('apps.configurator.manage-item.configuration-langs-titles') }}</LabelForm>
            </div>
            <DynamicForm
                v-model="languageValue"
                @input="getDinamicInputsLanguages"
                :languages="languages.map(lang => lang.acronym)"
                :columns=2
                 :isReadOnly="readOnly"
            />
        </div>
        <b-alert :show="errorsOnSave.length > 0" variant="danger" class="mt-5 rounded-lg">
            <ul class="list-none">
                <li v-for="(errorString, index) in errorsOnSave" :key="index">{{ errorString }}</li>
            </ul>
        </b-alert>

        <div class="action-item-button-container vx-col w-full mt-8">
            <button @click.stop="cancel" class="xs:w-full sm:w-auto rounded-lg sk-btn sk-btn-primary btn-border mr-2">
                {{ $t("apps.configurator.menu-list.cancel") }}
            </button>
            <button v-if="!readOnly" @click.stop="save" class="xs:w-full sm:w-auto rounded-lg sk-btn sk-btn-primary">
                {{ $t("apps.configurator.menu-list.save") }}
            </button>
        </div>
    </div>
</template>

<script>
import LabelForm from '@/modules/Shared/Components/form/LabelForm'
import DynamicForm from "@/modules/Shared/Components/form/DynamicForm";
import objectHelper from '@//modules/Shared/Helpers/objectHelper.js';
import SKModal from "@/modules/Shared/Components/modal/SKModal.vue";
import { v4 as uuidv4 } from 'uuid';
import Gallery from "@/modules/Apps/Components/resources/gallery/Gallery.vue";
import emptyAlert from "@/modules/Shared/Components/alert/emptyAlert.vue";
import ModalMenuItemGallery from "@/modules/Apps/Components/configurator/ModalMenuItemGallery.vue";
import Alert from "@/modules/Shared/Components/alert/Alert.vue";

export default {
    name: 'ManageMenuItemForm',
    props: {
        menu: {
            type: Object,
            required: true
        },
        templates: {
            type: Array,
            required: true
        },
        languages: {
            type: Array,
            required: true
        },
        resources: {
            type: Array,
            required: true
        },
        item: {
            type: Object,
            required: false
        },
        afterItemUuid: {
            type: String,
            required: false
        },
        insideItemUuid: {
            type: String,
            required: false
        },
        readOnly: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    components: {
        Alert,
        ModalMenuItemGallery,
        emptyAlert,
        Gallery,
        SKModal,
        LabelForm,
        DynamicForm
    },
    methods: {
        clearIcon() {
            this.internalItem.icon = ''
            this.$emit('loaded')
        },
        handleSelectIcon(icon) {
            this.internalItem.icon = icon.key
            this.$emit('loaded')
        },
        openGallery() {
            this.$refs.modalMenuItemGallery.open()
        },
        async open() {
            this.initialize()
        },
        internalSetItemsOrdering() {
            let ordering = 0

            const unparseItem = function (item) {
                return {
                    ...item,
                    ordering: ordering,
                    submenu_items: item.submenu_items.map((subitem) => unparseItem(subitem, ++ordering))
                }
            }

            this.menu.items = this.menu.items.map((item) => unparseItem(item, ++ordering))
        },
        async save() {
            if (!this.hasErrors()) {
                this.internalItem.configuration = this.configurationValue.values === null ? [] : this.configurationValue.values
                this.internalItem.titles = this.languageValue.values

                if (this.insideItemUuid) {
                    this.menu.items = this.addItemInside(this.menu.items, this.insideItemUuid)
                } else if (this.afterItemUuid) {
                    this.menu.items = this.addItemAfter(this.menu.items, this.afterItemUuid)
                } else {
                    if (this.action === 'create') {
                        this.menu.items.push(this.internalItem)
                    } else if (this.action === 'update') {
                        this.menu.items = this.replaceItem(this.menu.items, this.internalItem.uuid)
                    }
                }

                this.internalSetItemsOrdering()
                this.$emit('change')
            }
        },
        cancel() {
            this.$emit('cancel')
        },
        addItemInside(items, itemUuid) {
            return this.findItem(items, itemUuid, (items, index) => {
                items[index].submenu_items.splice(0, 0, this.internalItem)
                return items
            })
        },
        addItemAfter(items, itemUuid) {
            return this.findItem(items, itemUuid, (items, index) => {
                items.splice(index + 1, 0, this.internalItem)
                return items
            })
        },
        replaceItem(items, itemUuid) {
            return this.findItem(items, itemUuid, (items, index) => {
                items.splice(index, 1, this.internalItem)
                return items
            })
        },
        findItem(items, itemUuid, callback) {
            const index = items.findIndex(item => item.uuid === itemUuid)
            if (index !== -1) {
                return callback(items, index)
            }
            return items.map(item => {
                item.submenu_items = this.findItem(item.submenu_items, itemUuid, callback)
                return item
            })
        },
        hasErrors() {
            this.errorsOnSave = []

            if (!this.internalItem.width) {
                this.internalItem.width = 1
            }

            if (!this.internalItem.section) {
                this.errorsOnSave = [...this.errorsOnSave, this.$t('apps.configurator.manage-menu.error-section')]
            }

            return this.errorsOnSave.length !== 0
        },
        setItemConfigCustomizableOrNot() {
            if (!this.template.customizable) {
                this.internalItem.flag = this.template.flag
                this.internalItem.screen_name = this.template.screen_name
                this.isDisabled = true
            } else {
                this.isDisabled = false
            }

            this.internalItem.section = this.template.section
        },
        async changeTemplate(event) {
            this.template = this.findConfigurationInSection(event.target.value)
            this.setItemConfigCustomizableOrNot()

            if (this.template.configuration_template !== null && this.template.configuration_template !== false) {

                this.configurationValue = {
                    template: this.template.configuration_template,
                    values: this.template.customizable
                        ? this.template.configuration_template.reduce((obj, item) => {
                            obj[item.key] = "";
                            return obj;
                        }, {})
                        : this.template.configuration
                }

            } else {
                this.configurationValue = { values: {} }
            }

            if (objectHelper.arePropertiesEmpty(this.languageValue.values, this.languages.map(lang => lang.acronym))) {
                this.languageValue.values = this.template.titles
                const languages = this.languageValue.values
                if (this.template.customizable) {
                    Object.keys(this.languageValue.values).forEach(function(lang){
                        languages[lang] = ""
                    })
                }
            }

            this.$emit('loaded')
        },
        findConfigurationInSection(itemTemplateUuid) {
            const sectionFound = this.templates.find(section => section.uuid === itemTemplateUuid);
            return sectionFound ? sectionFound : false
        },
        getDinamicInputsLanguages(dynamicInputs) {
            this.languageValue = dynamicInputs
        },
        getDinamicInputsConfiguration(dynamicInputs) {
            for (let key in dynamicInputs.values) {
                if (dynamicInputs.values.hasOwnProperty(key) && typeof dynamicInputs.values[key] === 'boolean') {
                    dynamicInputs.values[key] = dynamicInputs.values[key].toString();
                }
            }
            this.configurationValue = dynamicInputs
        },
        getImageAcronym(image) {
            return image.split('/').pop().split('.')[0].split('_').pop()
        },
        initialize() {
            this.errorsOnSave = []
            this.isDisabled = false
            const userLang = this.$store.state.AppActiveUser.language

            if (!this.item) {
                this.action = 'create'
                this.configurationValue = { values: {} }
                this.internalItem = {
                    uuid: uuidv4(),
                    configuration: null,
                    flag: '',
                    icon: '',
                    item_template_uuid: null,
                    published: true,
                    screen_name: '',
                    section: '',
                    submenu_items: [],
                    titles: {},
                    width: 1
                }
            } else {
                this.action = 'update'
                this.internalItem = JSON.parse(JSON.stringify(this.item))

                this.template = this.findConfigurationInSection(this.internalItem.item_template_uuid)
                this.setItemConfigCustomizableOrNot()
                this.configurationValue = {
                    template: this.template.configuration_template,
                    values: this.internalItem.configuration
                }
            }

            this.languageValue = {
                template: this.languages.map(lang => ({
                    key: lang.acronym,
                    input: "textfield",
                    subtitle: { [userLang]: lang.acronym },
                    title: { [userLang]: lang.acronym },
                    required: true
                })),
                values: this.internalItem.titles
            }

            this.$emit('loaded');
        }
    },
    watch: {
        item() {
            this.initialize()
        }
    },
    data() {
        return {
            action: 'create',
            internalItem: null,
            configurationValue: { values: {} },
            languageValue: {},
            errorsOnSave: [],
            isDisabled: false,
            template: null,
            publishedOpts: [
                { label: this.$i18n.t('yes'), value: true },
                { label: this.$i18n.t('no'), value: false },
            ],
            widthOpts: [
                { label: this.$t('apps.configurator.manage-item.with-1-column'), value: 1 },
                { label: this.$t('apps.configurator.manage-item.with-2-column'), value: 2 },
                { label: this.$t('apps.configurator.manage-item.with-3-column'), value: 3 },
            ],
        }
    },
    computed: {
        imagesObject() {
            if (!this.internalItem || !this.internalItem.icon) {
                return null
            }

            const singleImageRegex = new RegExp(`/${this.internalItem.icon}\\.`)
            let images = this.resources.filter(resource => resource.match(singleImageRegex))

            if (images.length > 0) {
                return {
                    multiLanguage: false,
                    images: images
                }
            }

            const acronyms = this.languages.map(lang => lang.acronym)
            const multilanguageImageRegex = new RegExp(`/${this.internalItem.icon}_(${acronyms.join('|')})\\.`)
            images = this.resources.filter(resource => resource.match(multilanguageImageRegex))

            if (images.length > 0) {
                const missingLanguages = acronyms.filter(acronym => !images.map(this.getImageAcronym).includes(acronym))
                const extension = images[0].split('.').pop()
                return {
                    multiLanguage: true,
                    images: images,
                    missingImages: missingLanguages.map(acronym => `${this.internalItem.icon}_${acronym}.${extension}`),
                }
            }

            return null
        },
        templatesOpts() {
            if (this.templates !== null) {
                return this.templates.map(template => {
                    return {
                        label: template.name.scalar !== undefined
                            ? this.$t(template.name.scalar)
                            : template.name[this.$store.getters.applicationLanguage],
                        value: template.uuid
                    }
                }).sort((a, b) => {
                    return a.label < b.label ? -1 : a.label > b.label ? 1 : 0
                })
            }
        },
        showConfiguration() {
            if (this.template) {
                return this.template.configuration_template !== null
            } else {
                return false
            }
        }
    }
}
</script>
