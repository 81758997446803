<template>
    <div class="flex ml-0 flex-column w-full md:w-1/3 lg:w-1/3 pb-2">

        <h3>{{ $t('admin.apps.platform.android.title') }}</h3>

        <vs-input class="border-grey-light w-full" type="string" v-model="android.bundle"
                  :label="$t('admin.apps.platform.bundle')" required />

        <vs-input class="border-grey-light w-full" type="string" v-model="android.min_app_version"
                  :label="$t('admin.apps.platform.min_version')" required />

        <vs-input class="border-grey-light w-full" type="string" v-model="android.current_app_version"
                  :label="$t('admin.apps.platform.current_version')" required />

        <vs-input class="border-grey-light w-full" type="string" v-model="android.store_link"
                  :label="$t('admin.apps.platform.google_play_link')" required />


        <h3 class="mt-8">{{ $t('admin.apps.platform.ios.title') }}</h3>

        <vs-input class="border-grey-light w-full" type="string" v-model="ios.bundle"
                  :label="$t('admin.apps.platform.bundle')" required />

        <vs-input class="border-grey-light w-full" type="string" v-model="ios.min_app_version"
                  :label="$t('admin.apps.platform.min_version')" required />

        <vs-input class="border-grey-light w-full" type="string" v-model="ios.current_app_version"
                  :label="$t('admin.apps.platform.current_version')" required />

        <vs-input class="border-grey-light w-full" type="string" v-model="ios.store_link"
                  :label="$t('admin.apps.platform.apple_store_link')" required />

        <vs-input class="border-grey-light w-full" type="string" v-model="ios.watch_bundle"
                  v-if="hasWatch"
                  :label="$t('admin.apps.platform.watch_bundle')" required />

        <vs-input class="border-grey-light w-full" type="number" step="1" v-model="ios.apple_account_id"
                  :label="$t('admin.apps.platform.apple_account_id')" required />

    </div>
</template>

<script>
import Combobox from "@/modules/Shared/Components/Combobox.vue";
import loader from "@/modules/Shared/Mixins/loader";
import ApplicationService from "@/modules/Admin/Services/ApplicationService";

export default {
  name: "PlatformSettings",
  components: { Combobox },
  mixins: [loader],
  data() {
    return {
        android: {
            bundle: '',
            min_app_version: '',
            current_app_version: '',
            store_link: '',
        },
        ios: {
            bundle: '',
            min_app_version: '',
            current_app_version: '',
            store_link: '',
            apple_account_id: '',
            watch_bundle: '',
        },
        hasWatch: false,
    }
  },
  async created() {
    await this.loadAndNotifyError(async () => {
        const [android, ios, modules] = await Promise.allSettled([
            ApplicationService.getPlatform(this.$route.params.uuid, 'android'),
            ApplicationService.getPlatform(this.$route.params.uuid, 'ios'),
            ApplicationService.getApplicationModules(this.$route.params.uuid)
        ])

        this.hasWatch = !!modules.value.find(module => module.type.name === 'watch')

        if (android.status === 'fulfilled') {
            this.android = android.value
        }

        if (ios.status === 'fulfilled') {
            this.ios = ios.value
        }
    })
  },
  methods: {
    save() {
        return new Promise(async (resolve, reject) => {
            this.loadAndNotifyError(async () => {
                const applicationUuid = this.$route.params.uuid
                return Promise.all([
                    ApplicationService.updatePlatform(applicationUuid, 'android', this.android),
                    ApplicationService.updatePlatform(applicationUuid, 'ios', this.ios),
                ])
            }).then((result) => {
                if (!result) {
                    reject()
                    return;
                }
                resolve()
            })
        })
    }
  }
};
</script>
