<template>
    <div class="flex ml-0 flex-column w-full md:w-1/3 lg:w-1/3 pb-2">
        <vs-input class="border-grey-light w-full" type="text" v-model="interalApplication.name"
            :label="$t('admin.apps.main.name')" :danger="interalApplication.name == ''"/>

        <vs-checkbox class="ml-0 mb-4 mt-4 text-md font-bold text-dark" v-model="interalApplication.is_ski_app">
            {{ $t('admin.apps.main.is_ski_app') }}
        </vs-checkbox>

        <vs-select class="border-grey-light pb-2 w-full" v-model="interalApplication.status" required
            :label="$t('admin.apps.main.status')">
            <vs-select-item :key="option.key" :value="option.key" :text="option.label | capitalize"
                v-for="option in statusOptions" />
        </vs-select>

        <div class="vs-component vs-con-input-label vs-input w-full border-grey-light vs-input-primary" >
            <label for="" class="vs-input--label">{{ $t('admin.apps.main.status-comments') }}</label>
            <div class="vs-con-input">
                <textarea class="w-full h-32 rounded resize-none border-grey-light p-2"
                    v-model="interalApplication.status_comments" 
                />
            </div>
        </div>

        <vs-select class="border-grey-light pb-2 w-full" v-model="interalApplication.community_uuid" required
            :label="$t('admin.apps.main.community')" >
            <vs-select-item :key="option.uuid" :value="option.uuid" :text="option.name | capitalize"
                v-for="option in communities" />
        </vs-select>

        <vs-input class="border-grey-light w-full" type="text" v-model="interalApplication.conditions_id_ref"
            :label="$t('admin.apps.main.id-ref')"  :danger="interalApplication.conditions_id_ref == ''" />
        
        <vs-input class="border-grey-light w-full" type="text" v-model="interalApplication.scheme"
            :label="$t('admin.apps.main.scheme')"  :danger="interalApplication.scheme == ''" />

        <Combobox
            v-model="interalApplication.languages"
            :multiple="true"
            :options="spotlioLanguages.map(r => ({ key: parseInt(r.id), text: r.name }))"
            :label="$t('admin.apps.main.languages')"
        />

        <vs-select v-if="mainLanguages.length > 0" class="border-grey-light pb-2 w-full" v-model="interalApplication.main_language" required
            :label="$t('admin.apps.main.main-language')">
            <vs-select-item :key="option.key" :value="option.key" :text="option.label | capitalize"
                v-for="option in mainLanguages" 
            />
        </vs-select>
        
        <vs-select class=" pb-2 w-full" v-model="interalApplication.country_id" required
            :label="$t('admin.apps.main.country')">
            <vs-select-item :key="country.id" :value="parseInt(country.id)" :text="country.name | capitalize"
                v-for="country in countries" 
            />
        </vs-select>

        <vs-checkbox class="ml-0 mb-4 mt-4 text-md font-bold text-dark" v-model="hasSummerMode" @change="handleSummerMode">
            {{ $t('admin.apps.main.has-summer-mode') }}
        </vs-checkbox>
        
        <vs-input v-if="hasSummerMode" :danger="hasSummerMode && interalApplication.season_version === ''" class="border-grey-light w-full" type="number" step="1" v-model="interalApplication.season_version"
            :label="$t('admin.apps.main.summer-start-version')" required />

        <vs-input v-if="hasSummerMode" :danger="hasSummerMode && interalApplication.winter_start_date == ''" class="border-grey-light w-full" type="text" v-model="winter_start_date"
            :label="$t('admin.apps.main.winter-start-date')" required />

        <vs-input v-if="hasSummerMode" :danger="hasSummerMode && winter_end_date == ''" class="border-grey-light w-full" type="text" v-model="winter_end_date"
            :label="$t('admin.apps.main.winter-end-date')" required />
    </div>
</template>

<script>
import CommunityService from "@/modules/Admin/Services/CommunityService";
import Combobox from "@/modules/Shared/Components/Combobox.vue";
import CountryService from "@/modules/Shared/Services/CountryService";
import { mapGetters } from "vuex";
import loader from "@/modules/Shared/Mixins/loader";
import {unifyPaginatedResponse} from "@/modules/Shared/Helpers/apiResponseHelper";
import ApplicationService from "@/modules/Admin/Services/ApplicationService";

export default {
  name: "MainSettings",
  components: { Combobox },
  mixins: [loader],
  props: {
    application: {
        required: true,
    }
  },
  data() {
    return {
        createsApplication: true,
        interalApplication: {
            name: '',
            is_ski_app: false,
            status: 'development',
            status_comments: '',
            community_uuid: null,
            conditions_id_ref: '',
            scheme: '',
            languages: [],
            main_language: null,
            country_id: null,
            season_version: null,
            winter_start_month: null,
            winter_start_day: null,
            winter_end_month: null,
            winter_end_day: null,
        },
        hasSummerMode: false,
        statusOptions: [
            { key: 'development', label: 'admin.apps.main.status-development' },
            { key: 'production', label: 'admin.apps.main.status-production' },
            { key: 'onhold', label: 'admin.apps.main.status-onhold' },
            { key: 'cancelled', label: 'admin.apps.main.status-cancelled' }
        ],
        communities: [ ],
        countries: [ ],
        winter_start_date: '11-01',
        winter_end_date: '05-01',
    }
  },
  async created() {
    try {
        this.createsApplication = this.application === null
        this.interalApplication = this.application
        this.hasSummerMode = this.interalApplication.season_version !== null
        if (this.hasSummerMode) {
            this.winter_start_date = `${this.interalApplication.winter_start_month.toString().padStart(2, "0")}-${this.interalApplication.winter_start_day.toString().padStart(2, "0")}`
            this.winter_end_date = `${this.interalApplication.winter_end_month.toString().padStart(2, "0")}-${this.interalApplication.winter_end_day.toString().padStart(2, "0")}`
        }
    } catch (e) {
        console.warn(e)
    }
    await this.loadAndNotifyError(async () => {
        [this.communities, this.countries] = await Promise.all([
            unifyPaginatedResponse(CommunityService.getCommunities),
            unifyPaginatedResponse(CountryService.getCountries, {per_page: 30}),
        ])

        this.setSkitudeCommunityToFirstPlace()
        this.setSpainAsDefaultCountry()
    })
    this.interalApplication.languages = [ 1 ]
    this.interalApplication.main_language = 1
  },
  computed: {
    ...mapGetters(['spotlioLanguages']),
    mainLanguages() {
        return this.spotlioLanguages
            .filter((l) => this.interalApplication.languages.includes(l.id))
            .map(r => ({ key: parseInt(r.id), label: r.name }))
    },
  },
  watch: {
    winter_start_date(value) {
        const [month, day] = value.split('-')
        this.interalApplication.winter_start_month = parseInt(month)
        this.interalApplication.winter_start_day = parseInt(day)
    },
    winter_end_date(value) {
        const [month, day] = value.split('-')
        this.interalApplication.winter_end_month = parseInt(month)
        this.interalApplication.winter_end_day = parseInt(day)
    },
  },
  methods: {
    handleSummerMode(hasSummerMode) {
        if (hasSummerMode) {
            this.interalApplication.season_version = 0
            this.interalApplication.winter_start_month = 11
            this.interalApplication.winter_start_day = 1
            this.interalApplication.winter_end_month = 5
            this.interalApplication.winter_end_day = 1
        } else {
            this.interalApplication.season_version = null
            this.interalApplication.winter_start_month = null
            this.interalApplication.winter_start_day = null
            this.interalApplication.winter_end_month = null
            this.interalApplication.winter_end_day = null
        }
    },
    setSkitudeCommunityToFirstPlace() {
        this.communities.sort((a, b) => a.name.localeCompare(b.name))
        const index = this.communities.findIndex((c) => c.name == 'Skitude')
        const skitude = this.communities[index]
        this.communities.splice(index, 1)
        this.communities.splice(0, 0, skitude)
        this.interalApplication.community_uuid = skitude.uuid
    },
    setSpainAsDefaultCountry() {
        this.countries.sort((a, b) => a.name.localeCompare(b.name))
        const spain = this.countries.find((c) => c.name == 'Spain')
        this.interalApplication.country_id = parseInt(spain.id)
    },
    save() {
        return new Promise(async (resolve, reject) => {
            this.loadAndNotifyError(async () => {
                if (this.createsApplication) {
                    return ApplicationService.createApplication(this.$route.params.uuid, this.interalApplication)
                } else {
                    return ApplicationService.updateApplication(this.$route.params.uuid, this.interalApplication)
                }
            }).then((result) => {
                if (!result) {
                    reject()
                    return;
                }
                resolve()
            })
        })
    }
  }
};
</script>
