export default [
    {
        path: '/admin/apple-accounts',
        name: 'admin-apple-accounts',
        component: () => import('@/modules/Admin/Pages/AppleAccountsPage.vue'),
        meta: {
            parent: "admin",
            pageTitle: 'admin.apple_accounts.title',
            breadcrumb: [
                { title: 'admin.apple_accounts.title', url: `/admin/apple-accounts`, active: true }
            ]
        },
    },
]
