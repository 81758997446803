<template>
    <div v-if="value" class="vx-card p-8">
        <vs-input class="border-grey-light w-full" type="text" v-model="internalCommunity.name"
            :label="$t('admin.communities.name')" :danger="internalCommunity.name == ''"
            @input="$emit('input', internalCommunity)" />

        <vs-input class="border-grey-light w-full" type="text" v-model="internalCommunity.transactionals_sender_email"
            :label="$t('admin.communities.transactional_sender_email')"
            :danger="internalCommunity.transactionals_sender_email == ''" @input="$emit('input', internalCommunity)" />

        <div class="flex items-center ml-0 gap-4 mt-5">
            <vs-button class="rounded-lg xs:w-full sm:w-auto" @click="$emit('save')">
                {{ $t('resorts.touristic-objects.form.saveButton') }}
            </vs-button>
            <vs-button type="border" class="rounded-lg xs:w-full sm:w-auto text-primary" @click="$emit('cancel')">
                {{ $t('resorts.touristic-objects.form.cancelButton') }}
            </vs-button>
        </div>
    </div>
</template>

<script>
export default {
    name: "CommunityForm",
    components: {},
    mixins: [],
    props: [ 'value' ],
    data() {
        return {
            internalCommunity: {
                uuid: null,
                name: '',
                transactional_templates_prefix: '',
                transactionals_sender_email: '',
            }
        }
    },
    watch: {
        value() {
            this.internalCommunity = JSON.parse(JSON.stringify(this.value))
        }
    },
    methods: {

    }
};
</script>
