<template>
    <DatatablePageLayout>
        <template v-slot:actionButtons>
            <button class="sk-btn sk-btn-primary rounded-lg" @click="handleCreate">
                {{ $t('apps.campaigns.form.createButton') }}
            </button>
        </template>
        <template v-slot:datatable>
            <DataTable
                :config="datatableConfig"
                :elements="appleAccounts"
                :stripe="true"
                :draggable="false"
                :sst="true"
                :pagination="pagination"
                @action="handleAction"
                @filter="handleFilter"
            />
        </template>
    </DatatablePageLayout>
</template>

<script>

import DataTable from '@/modules/Shared/Components/table/SKDataTable.vue'
import loader from "@/modules/Shared/Mixins/loader";
import PaginationService from "@/modules/Shared/Services/PaginationService";
import DatatablePageLayout from "@/modules/Shared/Pages/DatatablePageLayout.vue";
import { v4 as uuidv4 } from 'uuid';
import AppleAccountService from "@/modules/Admin/Services/AppleAccountService";

export default {
  name: "AppleAccountsPage",
  components: {DatatablePageLayout, DataTable},
  mixins: [loader],
  data() {
    return {
      appleAccounts: [],
      datatableConfig: [
          { 'field' : 'team_name', head : this.$t('admin.apple_accounts.team_name'), 'searchable' : true, 'sortable' : false},
          { 'field' : 'type', head : this.$t('admin.challenges.settings.type_title'), 'searchable' : false, 'sortable' : false},
          { 'field' : 'team_id', head : this.$t('admin.apple_accounts.team_id'), 'searchable' : true, 'sortable' : false},
          { 'field' : 'actions', 'type': 'actions', head : this.$t("geofences.geofences-messages.actions"), 'searchable' : false, 'sortable' : false }
      ],
      pagination: {},
    }
  },
  async created() {
      await this.getAppleAccounts({
          filters: [],
          page: 1
      })
  },
  methods: {
      async getAppleAccounts(params) {
          await this.load(async () => {
              const appleAccounts = await AppleAccountService.getAppleAccounts(PaginationService.parseParameters(params))
              this.appleAccounts =  this.parseAppleAccounts(appleAccounts.data)
              this.pagination = {
                  per_page: appleAccounts.per_page,
                  total: appleAccounts.total
              }
          })
      },
      async handleFilter(params) {
          await this.getAppleAccounts(
              {
                  filters: params.filters,
                  page: params.page
              }
          )
      },
      handleCreate() {
          this.$router.push({
              name: 'admin-apple-account-create',
              params: {
                uuid: uuidv4()
              }
          });
      },
      async handleAction(type, uuid){
          switch(type) {
              case 'edit':
                  await this.$router.push({
                      name: 'admin-apple-account-create',
                      params: {
                          'uuid': uuid,
                      }
                  })
                  break
              case 'delete':
                  await this.deleteAppleAccount(uuid)
                  break
          }
      },
      parseAppleAccounts(applications) {
          return applications.map(application => ({
              ...application,
              actions: [ 'edit', 'delete' ]
          }))
      },

      async deleteAppleAccount(uuid) {
          await this.loadAndNotify(async () => {
              await AppleAccountService.deleteAppleAccount(uuid)
              await this.getAppleAccounts({
                  filters: [],
                  page: 1
              })
          })
      },
  }
};
</script>
