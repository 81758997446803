import {fromISOtoDBString} from "@/modules/Shared/Helpers/dateHelper";
import {DateTime, Duration} from "luxon";
import router from "@/router";

const getters = {
  apps: state => state.apps,
  languages: state => state.languages,
  default_language: state  => state.languages.find(language => language.is_default),
  campaign_form_languages: state => state.languages.map(language => ({...language, code: language.acronym, label: language.name })),
  campaign_selected_languages: state => state.campaigns.selection.languages.map(language => ({...language, code: language.acronym, label: language.name })),
  campaign_languages_is_default_selected: state => state.campaigns.selection.languages.find(language => language.is_default) !== undefined,
  campaign_selected_languages_acronyms: state => state.campaigns.selection.languages.map(language => language.acronym),
  campaign_contents: state => state.campaigns.content,
  campaign_default_language_content: (state, getters) => state.campaigns.content.find(content => content.language.acronym === getters.default_language.acronym),
  campaign_language_content: (state, getters) => (languageAcronym) => {
    return languageAcronym
      ? getters.campaign_contents.find(content => content.language.acronym === languageAcronym)
      : getters.campaign_contents[0]
  },
  campaign_selected_installations: state => state.campaigns.selection.installations,
  campaign_total_installations_count: (state, getters) => getters.campaign_contents.reduce((carry, item) => carry + item.count, 0),
  campaign_registration_selection_options: (state) => (resorts) => {
    if (resorts.length >= 2) return state.campaigns.form_options.registration
    return state.campaigns.form_options.registration.filter(registrationOption => registrationOption.code !== 'favorite_resort')
  },
  campaign_content_selection_options: (state) => (acronym) => {
    const baseOptions = {}

    if (!state.campaigns.selection.select_users && state.campaigns.selection.registration.code === 'only_registered') {
      baseOptions.only_registered = true
    }

    if (!state.campaigns.selection.select_users && state.campaigns.selection.registration.code === 'only_unregistered') {
      baseOptions.only_unregistered = true
    }

    if (!state.campaigns.selection.select_users && state.campaigns.selection.registration.code === 'favorite_resort') {
      baseOptions.favorite_resort_uuid = state.campaigns.selection.favorite_resort.code
    }

    if (state.campaigns.selection.installations.length > 0) {
      baseOptions.installation_uuids = state.campaigns.selection.installations.map(installation => installation.uuid)
    }

    if (acronym) {
      baseOptions.language = acronym
    }

    if (state.campaigns.category) {
      baseOptions.category = state.campaigns.category
    }

    return baseOptions
  },

  campaign_base_content: (state) => {
    return {
      title: '',
      message: '',
      image: '',
      count: 0,
      use_default_language_content: false,
      is_valid: false,
      ...(state.campaigns.selection.cta.code === 'url' && {url: ''})
    }
  },

  campaign_payloads: (state) => {
    const type = state.campaigns.selection.challenge === null ? 'standard' : 'challenge'
    const selection = state.campaigns.selection
    return state.campaigns.content.map(content => {
        return {
          application_uuid: router.currentRoute.params.uuid,
          type: type,
          category: !selection.select_users ? selection.category : null,
          ...(content.language && {language: content.language.acronym}),
          ...(selection.registration.code === 'only_registered' && {only_registered: true}),
          ...(selection.registration.code === 'only_unregistered' && {only_unregistered: true}),
          ...(selection.select_users && {installation_uuids: selection.installations.map(installation => installation.uuid)}),
          ...(selection.favorite_resort && {favorite_resort_uuid: selection.favorite_resort.code}),
          title: content.title,
          message: content.message,
          ...(content.url && {url: content.url}),
          ...(content.image && {image: content.image.content}),
          ...(type === 'challenge' && {challenge_uuid: selection.challenge.code}),
          to_be_delivered_at: state.campaigns.schedule_date
            ? fromISOtoDBString(state.campaigns.schedule_date)
            : fromISOtoDBString((new Date()).toISOString()),
          visible: !state.campaigns.selection.hide,
          ttl: state.campaigns.ttl
        }
    })
  },

  campaign_edit_mode: (state) => state.campaigns.edit_mode,
  campaign_send_now: (state) => state.campaigns.selection.send_now,
  campaign_expiration_date: (state) => {
    const scheduleDate = state.campaigns.schedule_date
      ? DateTime.fromISO(state.campaigns.schedule_date)
      : DateTime.now()

    const ttl = state.campaigns.ttl

    return scheduleDate.plus(Duration.fromObject({ days: Math.floor(ttl / 24), hour: ttl % 24 })).toJSDate()
  },
  campaign_is_valid: (state, getters) => (hasNotificationsAllowed) => {
    const invalidContents = state.campaigns.content.filter(content => !content.is_valid)
    const validContent = invalidContents.length === 0
      && getters.campaign_total_installations_count !== 0
      && (state.campaigns.selection.send_now || state.campaigns.schedule_date !== null)

    if (state.campaigns.selection.installations.length) {
      return validContent
    }

    return hasNotificationsAllowed ? (validContent && state.campaigns.selection.category) : validContent
  },
  campaign_is_segmented_by_selected_users: (state) => state.campaigns.selection.select_users,
}

export default getters
