<template>
    <div>
        <div class="vx-card p-8 w-full">
            <PreviewPhotoModal
                ref="previewPhotoModal"
                :image="previewImage"
            />

            <h4 class="mb-6">{{ $t('admin.photos.filters') }}</h4>
            <div class="flex flex-col md:flex-row gap-8">
                <div class="w-full md:w-1/2">
                    <Combobox
                        v-if="applicationIds.length > 0"
                        v-model="filters.application_id"
                        class="my-4"
                        :multiple="false"
                        :options="apps.map(app => ({ key: app.id, text: app.name }))"
                        :label="$t('admin.photos.applications')"
                    />

                    <Combobox
                        v-if="resortIds.length > 0"
                        v-model="filters.resort_id"
                        class="my-4"
                        :multiple="false"
                        :options="resorts.map(r => ({ key: r.id, text: r.name }))"
                        :label="$t('admin.photos.resorts')"
                    />
                </div>
            </div>

            <div class="flex items-center ml-0 gap-4 mt-5">
                <vs-button class="rounded-lg xs:w-full sm:w-auto ml-2 " @click="handleFilter">
                    {{ $t('admin.photos.filter') }}
                </vs-button>
                <vs-button type="border" class="rounded-lg xs:w-full sm:w-auto text-primary" @click="handleReset">
                    {{ $t('admin.photos.reset') }}
                </vs-button>
            </div>
        </div>

        <div>
            <h4 class="mt-6 mb-4">{{ $t('admin.photos.title') }}</h4>
            <div v-if ="photos.length > 0" class="flex flex-row flex-wrap gap-4">
                <PhotoCard
                    v-for="photo in photos"
                    :key="photo.id"
                    :thumbnail="photo.url"
                    :username="photo.owner.username"
                    :is-public="photo.isPublic"
                    :is-published="photo.published"
                    :application="photo.application.name"
                    :resort="photo.resort.name"
                    @preview="() => handlePhotoPreview(photo.url)"
                    @publishChange="() => handlePhotoPublishChange(photo)"
                />
            </div>
            <p v-else>
                {{ $t('admin.photos.no_photos') }}
            </p>
            <vs-pagination
                v-if="pagination.total_pages > 1"
                class="mt-base"
                :total="pagination.total_pages"
                :value="pagination.current_page"
                @change="handlePageChange"
            />
        </div>
    </div>

</template>

<script>

import loader from "@/modules/Shared/Mixins/loader";
import PaginationService from "@/modules/Shared/Services/PaginationService";
import Combobox from "@/modules/Shared/Components/Combobox.vue";
import ResortService from "@/modules/Apps/Services/ResortService";
import {unifyPaginatedResponse} from "@/modules/Shared/Helpers/apiResponseHelper";
import ApplicationService from "@/modules/Shared/Services/ApplicationService";
import PhotoService from "@/modules/Admin/Services/PhotoService";
import PhotoCard from "@/modules/Admin/Components/Photos/PhotoCard.vue";
import PreviewPhotoModal from "@/modules/Admin/Components/Photos/PreviewPhotoModal.vue";

export default {
    name: "GeophotoChallengeGallery",
    components: {PreviewPhotoModal, PhotoCard, Combobox},
    mixins: [loader],
    props: {
        resortIds: {
            type: Array,
            required: true,
            default: () => []
        },
        applicationIds: {
            type: Array,
            required: true,
            default: () => []
        },
        userIds: {
            type: Array,
            required: true,
            default: () => []
        },
        startDate: {
            type: String,
            required: true
        },
        endDate: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            apps: [],
            resorts: [],

            photos: [],
            previewImage: null,

            filters: {
                resort_id: null,
                application_id: null,
            },

            fixedFilters: {},

            pagination: {
                total_pages: 1,
                current_page: 1
            },
        }
    },
    async created() {
        this.buildFixedFilters()

        if (this.resortIds.length > 0) {
            this.filters.resort_id = this.resortIds[0].toString()
        }

        if (this.applicationIds.length > 0) {
            this.filters.application_id = this.applicationIds[0].toString()
        }

        await this.loadAndNotifyError(async () => {
            const [resorts, apps] = await Promise.all([
                this.resortIds.length > 0
                    ? unifyPaginatedResponse(ResortService.getResorts, {per_page: 30})
                    : Promise.resolve([])
                ,
                this.applicationIds.length > 0
                    ? unifyPaginatedResponse(ApplicationService.getApplications, {per_page: 30})
                    : Promise.resolve([])
                ,
                this.fetchPhotos()
            ])

            this.resorts = resorts.filter(r => this.resortIds.includes(parseInt(r.id)))
            this.apps = apps.filter(a => this.applicationIds.includes(parseInt(a.id)))
        })
    },
    methods: {
        buildFixedFilters() {
            this.fixedFilters = {
                published: 1,
                private: 2,
                user_id: this.userIds.join(';'),
                date: this.startDate + ';' + this.endDate
            }
        },
        async handlePhotoPublishChange(photo) {
            await this.loadAndNotifyError(async () => {
                const params = {
                    published: !photo.published
                }

                await PhotoService.editPhoto(photo.uuid, params)
                photo.published = !photo.published
            })
        },
        handlePhotoPreview(image) {
            this.previewImage = image
            this.$refs.previewPhotoModal.open()
        },
        async handlePageChange(page) {
            if (this.pagination.current_page !== page) {
                await this.fetchPhotos(page)
            }
        },
        async handleFilter() {
            await this.fetchPhotos()
        },
        handleReset() {
            this.filters = {
                resort_id: this.resortIds.length > 0 ? this.resortIds[0].toString() : null,
                application_id: this.applicationIds.length > 0 ? this.applicationIds[0].toString() : null
            }
        },
        async fetchPhotos(page = 1) {
            return this.loadAndNotifyError(async () => {
                const filters = {
                    ...this.fixedFilters,
                    ...this.filters
                }
                const params = PaginationService.parseParameters({
                    filters: Object.entries(filters).map(([key, value]) => ({
                        field: key,
                        value: value
                    })).filter(filter => filter.value !== null),
                })

                const photosResponse = await PhotoService.getPhotos(params, page)

                this.pagination = {
                    total_pages: Math.ceil(photosResponse.total / photosResponse.per_page),
                    current_page: photosResponse.current_page
                }

                this.photos = photosResponse.data
            })
        }
    }
};
</script>
