<template>
  <div class="mt-4">
    <CommunityForm v-model="community"
      @cancel="$router.push({name: 'admin-communities'})" 
      @save="save" 
    />
  </div>
</template>

<script>

import CommunityService from "@/modules/Admin/Services/CommunityService";
import CommunityForm from "@/modules/Admin/Components/Communities/CommunityForm";
import store from "@/modules/Shared/Store/store";
import loader from "@/modules/Shared/Mixins/loader";

export default {
  name: "EditCommunityPage",
  components: { CommunityForm },
  mixins: [loader],

  data() {
    return {
      community: null,
      new: true
    }
  },
  
  async beforeRouteEnter (to, from, next) {
      let defaultCommunity = {
        uuid: to.params.uuid,
        name: '',
        transactional_templates_prefix: '-',
        transactionals_sender_email: '',
      }
      const community = await CommunityService.getCommunity(to.params.uuid);
      await store.dispatch('setRouteElement', community)
      next(vm => { 
        vm.community = community || defaultCommunity 
        vm.new = !community
      })
  },

  methods: {
    save() {
      this.loadAndNotify(async () => {
        if (this.new) {
          await CommunityService.createCommunity(this.community.uuid, this.community);
        } else {
          await CommunityService.updateCommunity(this.community.uuid, this.community);
        }
      })
    }
  }
};
</script>
