<template>
    <b-tab :title="title" :disabled="internalDisabled">
        <slot />
        <div class="flex items-center ml-0 gap-4 mt-5">
            <button v-if="!isFirstStep" class="sk-btn sk-btn-primary rounded-lg btn-border" @click="backStep">{{ $t('shops.back') }}</button>
            <button class="sk-btn sk-btn-primary rounded-lg" @click="finishStep">{{ $t('shops.save') }}</button>
        </div>
    </b-tab>
</template>
<script>
export default {
    name: 'SKStepperStep',
    props: {
        title: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        beforeComplete: {
            type: Function,
            default: () => Promise.resolve()
        },
    },
    data() {
        return {
            internalDisabled: false,
        }
    },
    computed: {
        isFirstStep() {
            return this.$parent.$parent.getFirstStep() === this
        }
    },
    created() {
        this.internalDisabled = this.disabled
    },
    methods: {
        disable() {
            this.internalDisabled = true
        },
        enable() {
            this.internalDisabled = false
        },
        finishStep() {
            this.beforeComplete().then(() => {
                this.$emit('onComplete')
                this.$parent.$parent.stepFinished(this)
            }).catch(e => console.error(e))
        },
        backStep() {
            this.$parent.$parent.stepBack(this)
        }
    }
}
</script>