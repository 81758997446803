<template>
    <div>
        <label class="mt-8">
            <b><slot>{{ $t("Label") }} </slot></b>
            <span v-if="extra" class="font-thin"> {{ extra }} </span>
            <span>
                <vx-tooltip v-if="info" :text="info" position="top">
                    <vs-icon icon="help" round bg="white" color="primary"></vs-icon>
                </vx-tooltip>
            </span>
        </label>
    </div>
</template>

<script>
export default {
    name: 'label-form',
    props: {
        info: String,
        extra: String
    }
}
</script>

<style>
    .con-vs-tooltip {
        @apply inline-block ml-2
    }
</style>
