<template>
  <div>
    <h5 class="mb-4">{{ this.$t('apps.campaigns.section_segmentation_title') }}</h5>
    <vs-checkbox :disabled="campaign_edit_mode" class="ml-0 mb-2" v-model="selectUsers">{{ this.$t('apps.campaigns.section_segmentation_select_users_checkbox') }}</vs-checkbox>
    <div v-if="!selectUsers " class="vx-row pl-4">
      <v-select multiple
                class="w-1/4"
                v-model="selectedLanguages"
                :options="campaign_form_languages"
                :disabled="campaign_edit_mode"
                :placeholder="this.$t('apps.campaigns.section_segmentation_select_languages_placeholder')"
      />

      <v-select class="w-1/4 ml-2"
                :clearable="false"
                v-model="selectedRegistration"
                :options="isSkiApp
                  ? $store.getters['apps/campaign_registration_selection_options'](resorts)
                  : $store.getters['apps/campaign_registration_selection_options'](resorts).filter(option => option.code !== 'only_registered')"
                :disabled="campaign_edit_mode"
      />

      <v-select v-if="selectedRegistration.code === 'favorite_resort' && resorts.length > 1"
                class="w-1/4 ml-2"
                :clearable="false"
                v-model="selectedResort"
                :options="resorts"
                :disabled="campaign_edit_mode"
      />

      <v-select v-if="hasNotificationsAllowed" :key="availableCategories.length"
                class="w-1/4 ml-2"
                :clearable="false"
                v-model="selectedCategory"
                :options="availableCategories"
                :placeholder="this.$t('apps.campaigns.section_segmentation_select_category_placeholder') + ' *'"
                :required="!selectedCategory"
                :disabled="campaign_edit_mode"
        >
          <template v-slot:option="option">
            <li v-if=" option.type === 'create' ">
              <span @click="createNew()">
                {{option.text}}
              </span>
            </li>
            <li v-else >{{ option.label }}</li>
          </template>
      </v-select>

    </div>
    <div v-else class="pl-4">
      <div class="vx-row">
        <vs-button v-if="!campaign_edit_mode && !campaign_selected_installations.length" color="primary" type="border" icon="add" class="rounded-lg" @click="isModalOpen = true">
          {{ this.$t('apps.campaigns.section_segmentation_select_users_add_button') }}
        </vs-button>
        <vs-button v-if="!campaign_edit_mode && campaign_selected_installations.length" color="primary" type="border" class="rounded-lg" @click="isModalOpen = true">
          {{ this.$t('apps.campaigns.section_segmentation_select_users_updated_button') }}
        </vs-button>
        <vs-button v-if="campaign_selected_installations.length && !campaign_edit_mode" color="danger" type="border" class="rounded-lg ml-4" @click="clearCampaignInstallations">
          {{ this.$t('apps.campaigns.section_segmentation_select_users_clear_button') }}
        </vs-button>
      </div>
      <div v-if="campaign_selected_installations" class="vx-row mt-4">
        <div
          v-for="selectedInstallation in campaign_selected_installations"
          :key="selectedInstallation.uuid"
          @click="handleRemoveInstallation(selectedInstallation.uuid)"
          :class="[
            {'cursor-default': campaign_edit_mode, 'cursor-pointer': !campaign_edit_mode},
            'w-1/6', 'p-3', 'mr-2', 'mb-2', 'flex', 'flex-row', 'items-center', 'justify-between', 'bg-white', 'border-solid', 'border-grey-light', 'border-1', 'rounded'
            ]"
        >
          <div class="flex flex-column">
            <span>{{ selectedInstallation.username }}</span>
            <span class="text-xs text-grey font-semibold">{{ selectedInstallation.device }}</span>
          </div>

          <span v-if="!campaign_edit_mode">&#10005;</span>
        </div>
      </div>
    </div>
    <div class="modalUsers">
      <vs-popup button-close-hidden fullscreen :active.sync="isModalOpen" title="">
        <InstallationsDatatable
          v-if="isModalOpen"
          :selected-installations="campaign_selected_installations"
          @cancel="isModalOpen = false"
          @commit="handleInstallationsCommit"
        />
      </vs-popup>
    </div>

    <div v-if="isCreateModalOpen">
            <CreateOrEditCategoryForm
            :selectedCategory = newCategory
            :languages = languages
            :isNew = isNew
            :aplicationUuid = aplicationUuid
            :isModalOpen=isCreateModalOpen
            @updateList = updateList()
            ></CreateOrEditCategoryForm>
    </div>

  </div>
</template>

<script>

import InstallationsDatatable from '@/modules/Apps/Components/campaigns/InstallationsDatatable'
import {mapActions, mapGetters, mapState} from "vuex";
import AppsService from "@/modules/Apps/Services/AppsService";
import CampaignCategoriesService from "@/modules/Apps/Services/CampaignCategoriesService";
import  CreateOrEditCategoryForm  from "@/modules/Apps/Components/campaigns/form/CreateOrEditCategoryForm"
import router from "@/router";
import { v4 as uuidv4 } from 'uuid';

export default {
    name: 'campaign-segmentation',
    components: {
      InstallationsDatatable,
      CreateOrEditCategoryForm
    },
    data () {
      return {
          isModalOpen: false,
          resorts: [],
          availableCategories:[],
          newCategory:{},
          isNew:true,
          isCreateModalOpen:false,
          aplicationUuid: router.currentRoute.params.uuid,
      }
    },
    props: {
      hasDRMCampaignCategoriesAllowed: {
          type: Boolean
      },
      hasNotificationsAllowed: {
          type: Boolean,
      },
      isSkiApp: {
          type: Boolean
      },
    },
    async created() {
      const resorts = await AppsService.getResorts(this.$route.params.uuid)
      this.resorts = resorts.map(resort => ({ code: resort.uuid, label: resort.name }))
      this.getCampaignCategories()
    },
    computed: {
      selectUsers: {
        get() { return this.$store.state.apps.campaigns.selection.select_users },
        set(value) {
          if (value) {
            this.$store.dispatch('apps/enableCampaignSelectUsers')
          } else {
            this.$store.dispatch('apps/disableCampaignSelectUsers')
          }
          this.$store.commit('apps/SET_CAMPAIGN_SELECT_USERS', value)
        }
      },
      selectedLanguages: {
        get() { return this.campaign_selected_languages },
        set(languages) {
          let commit = false
          const oldLanguages = this.campaign_selected_languages
          const newLanguagesAcronyms = languages.map(language => language.acronym)

          if (languages.length > oldLanguages.length) {
            const addedLanguage = languages.find(language => !this.campaign_selected_languages_acronyms.includes(language.acronym))
            this.addCampaignLanguageContent({language: addedLanguage, content: null})
            commit = true
            this.$store.commit('apps/SELECT_CAMPAIGN_LANGUAGES', languages)
          } else {
            const removedLanguageAcronym = this.campaign_selected_languages_acronyms.find(acronym => !newLanguagesAcronyms.includes(acronym))
            this.removeCampaignLanguageContent(removedLanguageAcronym)
            commit = true
          }

          if (commit) this.$store.commit('apps/SELECT_CAMPAIGN_LANGUAGES', languages)
        }
      },
      selectedRegistration: {
        get() { return this.$store.state.apps.campaigns.selection.registration },
        set(value) {
          if (value.code === 'favorite_resort') {
            this.$store.commit('apps/SET_CAMPAIGN_FAVORITE_RESORT', this.resorts[0])
          } else {
            this.$store.commit('apps/SET_CAMPAIGN_FAVORITE_RESORT', null)
          }
          this.$store.commit('apps/SET_CAMPAIGN_REGISTRATION', value)
          this.recalculateAllContentInstallations()
        }
      },
      selectedResort: {
        get() { return this.$store.state.apps.campaigns.selection.favorite_resort },
        set(value) {
          this.$store.commit('apps/SET_CAMPAIGN_FAVORITE_RESORT', value)
          this.recalculateAllContentInstallations()
        }
      },
      selectedCategory: {
        get() { return this.$store.state.apps.campaigns.selection.category
            ? this.availableCategories.find(category => category.code === this.$store.state.apps.campaigns.selection.category)
            : null
        },
        set(value) {
            this.$store.commit('apps/SET_CAMPAIGN_CATEGORY', value.code)
            this.recalculateAllContentInstallations()
        }
      },
      ...mapGetters('apps', [
        'campaign_form_languages',
        'campaign_selected_languages',
        'campaign_selected_languages_acronyms',
        'campaign_selected_installations',
        'campaign_edit_mode',
        'languages'
      ]),
      ...mapState('apps', [
        'campaigns',
        'languages'
      ]),
    },
    methods: {
      ...mapActions('apps', [
        'addCampaignLanguageContent',
        'removeCampaignLanguageContent',
        'selectCampaignInstallations',
        'removeCampaignInstallation',
        'clearCampaignInstallations',
        'recalculateAllContentInstallations'
      ]),
      handleInstallationsCommit(installations) {
        this.selectCampaignInstallations(installations)
        this.isModalOpen = false
      },

      handleRemoveInstallation(uuid) {
        if (this.campaign_edit_mode) return
        this.removeCampaignInstallation(uuid)
      },
      async getCampaignCategories(){

        let baseFilters = [
            {
                field: 'application_uuid',
                value: router.currentRoute.params.uuid
            },
            {
                field: 'published_at',
                value: true
            },
        ]
        let categories = await CampaignCategoriesService.getCampaignsCategories(baseFilters,  30)
        this.availableCategories = categories.data.map(category => ({ code: category.uuid, label: category.reference }))

        if(this.hasDRMCampaignCategoriesAllowed){
          this.availableCategories.push({ type :'create', label : null,text:this.$t('apps.campaigns.section_segmentation_select_create_category_button')  });
        }

      },
      async updateList(){
        this.isCreateModalOpen = false
        this.getCampaignCategories()
      },
      async createNew(){

          this.isNew = true
          this.newCategory = {
              uuid: uuidv4(),
              reference: "",
              published:false,
              locales:{}
          }
          this.isCreateModalOpen = true
        }
    },
}
</script>
