<template>
    <div class="flex ml-0 flex-column w-full md:w-1/3 lg:w-1/3 pb-2">

        <vs-input class="border-grey-light w-full" type="string" v-model="payload.support_phone"
                  :label="$t('admin.apps.resorts.support_phone')" />

        <vs-input class="border-grey-light w-full" type="string" v-model="payload.support_email"
                  :label="$t('admin.apps.resorts.support_email')" />

        <Combobox
            v-model="payload.resort_ids"
            class="my-4"
            :multiple="true"
            :options="resorts.map(r => ({ key: r.id, text: r.name }))"
            :asyncFirstFetchCallback="fetchNewResorts"
            :asyncNextFetchCallback="fetchNextResorts"
            :label="$t('admin.challenges.settings.resorts')"
        />
    </div>
</template>

<script>
import Combobox from "@/modules/Shared/Components/Combobox.vue";
import loader from "@/modules/Shared/Mixins/loader";
import ApplicationService from "@/modules/Admin/Services/ApplicationService";
import ResortService from "@/modules/Apps/Services/ResortService";
import PaginationService from "@/modules/Shared/Services/PaginationService";

export default {
  name: "ResortsSettings",
  components: { Combobox },
  mixins: [loader],
  data() {
    return {
        payload: {
            resort_ids: [],
            support_phone: '',
            support_email: '',
        },
        resorts: [],
        resortComboboxPage: 0,
        resortComboboxEnded: false,
    }
  },
  async created() {
    await this.loadAndNotifyError(async () => {
        this.payload = await ApplicationService.getResorts(this.$route.params.uuid)
        if (this.payload.resort_ids.length > 0) {
            const chunkSize = 30
            const chunks = []
            for (let i = 0; i < this.payload.resort_ids.length; i += chunkSize) {
                chunks.push(this.payload.resort_ids.slice(i, i + chunkSize));
            }

            const responses = await Promise.all(chunks.map(chunk => ResortService.getResorts(PaginationService.parseParameters({
                filters: [ { field: 'id', value: chunk.join(';') } ],
                per_page: chunkSize,
            }))))

            this.resorts = responses.map(response => response.data).flat()
        }
    })
  },
  methods: {
    async fetchResorts(filter, page = 1) {
        const response = await ResortService.getResorts(
            filter ? PaginationService.parseParameters({
                filters: [ { field: 'name', value: filter } ],
                per_page: 30,
            }) : {per_page: 30},
            page
        )

        if (response.data.length < 30) {
            this.resortComboboxEnded = true
        }

        this.resorts = [...this.resorts, ...response.data]
    },
    fetchNextResorts(filter) {
        if (this.resortComboboxEnded) {
            return
        }
        this.resortComboboxPage += 1
        return this.fetchResorts(filter, this.resortComboboxPage)
    },
    fetchNewResorts(filter) {
        this.resorts = []
        this.resortComboboxPage = 1
        return this.fetchResorts(filter, this.resortComboboxPage)
    },
    save() {
        return new Promise(async (resolve, reject) => {
            this.loadAndNotifyError(async () => {
                return ApplicationService.updateResorts(this.$route.params.uuid, this.payload)
            }).then((result) => {
                if (!result) {
                    reject()
                    return;
                }
                resolve()
            })
        })
    }
  }
};
</script>
