<template>
    <div>
        <SKStepper v-if="ready" :asTabs="application !== null">
            <SKStepperStep :beforeComplete="() => $refs.mainSettings.save()" :title="$t('admin.apps.main.title')">
                <MainSettings ref="mainSettings" :application="application"/>
            </SKStepperStep>

            <SKStepperStep :beforeComplete="() => $refs.moduleSettings.save()" :title="$t('admin.apps.modules.title')">
                <ModuleSettings ref="moduleSettings"/>
            </SKStepperStep>

            <SKStepperStep :beforeComplete="() => $refs.authSettings.save()" :title="$t('admin.apps.auth.title')">
                <AuthSettings ref="authSettings"/>
            </SKStepperStep>

            <SKStepperStep :beforeComplete="() => $refs.platformSettings.save()" :title="$t('admin.apps.platform.title')">
                <PlatformSettings ref="platformSettings"/>
            </SKStepperStep>

            <SKStepperStep :beforeComplete="() => $refs.resortsSettings.save()" :title="$t('admin.apps.resorts.title')">
                <ResortsSettings ref="resortsSettings"/>
            </SKStepperStep>
        </SKStepper>
    </div>
</template>

<script>

import SKStepper from '@/modules/Shared/Components/stepper/SKStepper'
import SKStepperStep from '@/modules/Shared/Components/stepper/SKStepperStep';
import MainSettings from "@/modules/Admin/Components/Apps/MainSettings.vue";
import ModuleSettings from "@/modules/Admin/Components/Apps/ModuleSettings.vue";
import AuthSettings from "@/modules/Admin/Components/Apps/AuthSettings";
import ApplicationService from "@/modules/Admin/Services/ApplicationService";
import PlatformSettings from "@/modules/Admin/Components/Apps/PlatformSettings.vue";
import ResortsSettings from "@/modules/Admin/Components/Apps/ResortsSettings.vue";

export default {
  name: "EditApplicationsPage",
  components: { ResortsSettings, PlatformSettings, ModuleSettings, SKStepper, SKStepperStep, MainSettings, AuthSettings },
  data() {
    return {
        application: null,
        ready: false,
    }
  },
  async created() {
    try {
        this.application = await ApplicationService.getApplication(this.$route.params.uuid)
    } finally {
        this.ready = true
    }
  }
};
</script>
