<template>
    <div class="flex ml-0 flex-column w-full md:w-1/3 lg:w-1/3 pb-2">

        <vs-checkbox class="ml-0 mb-4 mt-4 text-md font-bold text-dark" v-model="hasLogin" @change="handleLogin">
            {{ $t('admin.apps.auth.has_login') }}
        </vs-checkbox>

        <div v-if="hasLogin">
            <Combobox
                v-model="payload.providers"
                :multiple="true"
                :options="providersList"
                :label="$t('admin.apps.auth.providers.title')"
            />

            <vs-input :danger="hasLogin && payload.min_version === ''" class="border-grey-light w-full" type="number" min="0" step="0.1" v-model="payload.mandatory_login_min_version"
                :label="$t('admin.apps.auth.login_mandatory_min_version')" required />
            
            <vs-input class="border-grey-light w-full" type="number" step="0.1" v-model="payload.mandatory_login_max_version"
                :label="$t('admin.apps.auth.login_mandatory_max_version')" required />
        </div>
    </div>
</template>

<script>
import Combobox from "@/modules/Shared/Components/Combobox.vue";
import loader from "@/modules/Shared/Mixins/loader";
import ApplicationService from "@/modules/Admin/Services/ApplicationService";

export default {
  name: "AuthSettings",
  components: { Combobox },
  mixins: [loader],
  data() {
    return {
        payload: {
            providers: ['google', 'apple', 'email', 'skitude'],
            mandatory_login_min_version: 0,
            mandatory_login_max_version: null,
        },
        hasLogin: false,
        providersList: [
            { key: 'apple', text: 'Apple' },
            { key: 'bpass', text: 'Baqueira pass' },
            { key: 'email', text: 'Email' },
            { key: 'google', text: 'Google' },
            { key: 'openid', text: 'Open ID' },
            { key: 'pirineus365', text: 'Pirineu 365' },
            { key: 'sierra_nevada', text: 'Sierra Nevada' },
            { key: 'skitude', text: 'Skitude' },
        ]
    }
  },
  async created() {
    this.loadAndNotifyError(async () => {
        this.payload = await ApplicationService.getAuthProviders(this.$route.params.uuid)
        this.hasLogin = this.payload.mandatory_login_min_version !== null
    })
  },
  computed: {
   
  },
  
  methods: {
    handleLogin() {
        if (!this.hasLogin) {
            this.payload.providers = []
            this.payload.mandatory_login_min_version = null
            this.payload.mandatory_login_max_version = null
        }
    },
    save() {
        return new Promise(async (resolve, reject) => {
            this.loadAndNotifyError(async () => {
                const applicationUuid = this.$route.params.uuid
                return ApplicationService.updateAuthProviders(applicationUuid, this.payload)
            }).then((result) => {
                if (!result) {
                    reject()
                    return;
                }
                resolve()
            })
        })
    }
  }
};
</script>
