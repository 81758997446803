import axios from '@/axios.js'

export default {
    getCommunities: async (page = 1) => {
        let response = await axios.adminapi.get(`v4/communities`,{
            params: {
                page: page,
            }
        })
        return response.data.object
    },

    getCommunity: async (uuid) => {
        let response = await axios.adminapi.get(`v4/communities?q=uuid:${uuid}`)
        return response.data.object.data[0]
    },

    updateCommunity: async (uuid, payload) => {
        let response = await axios.adminapi.put(`v4/communities/${uuid}`, payload)
        return response.data.object
    },

    createCommunity: async (uuid, payload) => {
        let response = await axios.adminapi.post(`v4/communities/${uuid}`, payload)
        return response.data.object
    },
}
