<template>
    <DatatablePageLayout>
        <template v-slot:actionButtons>
            <button class="sk-btn sk-btn-primary rounded-lg" @click="handleCreate">
                {{ $t('apps.campaigns.form.createButton') }}
            </button>
        </template>
        <template v-slot:datatable>
            <DataTable
                :config="datatableConfig"
                :elements="communities"
                :stripe="true"
                :draggable="false"
                :sst="true"
                :pagination="pagination"
                @action="handleAction"
                @filter="handleFilter"
            />
        </template>
    </DatatablePageLayout>
</template>

<script>

import DataTable from '@/modules/Shared/Components/table/SKDataTable.vue'
import loader from "@/modules/Shared/Mixins/loader";
import DatatablePageLayout from "@/modules/Shared/Pages/DatatablePageLayout.vue";
import CommunityService from "@/modules/Admin/Services/CommunityService";
import { v4 as uuidv4 } from 'uuid';

export default {
  name: "CommunitiesPage",
  components: {DatatablePageLayout, DataTable},
  mixins: [loader],
  data() {
    return {
      applications: [],
      communities: [],
      datatableConfig: [
          { 'field' : 'uuid', head : this.$t('shops.id'), 'searchable' : false, 'sortable' : false},
          { 'field' : 'name', head : this.$t('shops.name'), 'searchable' : false, 'sortable' : false},
          { 'field' : 'actions', 'type': 'actions', head : this.$t("geofences.geofences-messages.actions"), 'searchable' : false, 'sortable' : false }
      ],
      pagination: {},
    }
  },
  async created() {
      await this.getCommunities()
  },
  methods: {
      async getCommunities(page = 1) {
          await this.load(async () => {
              const communities = await CommunityService.getCommunities(page)
              this.communities = communities.data.map(c => ({ ...c, actions: [ 'edit' ] }))
              this.pagination = {
                  per_page: communities.per_page,
                  total: communities.total
              }
          })
      },
      handleCreate() {
          this.$router.push({
              name: 'admin-communities-edit',
              params: {
                uuid: uuidv4(),
              }
          });
      },
      async handleFilter(params) {
          await this.getCommunities(params.page)
      },
      async handleAction(type, uuid){
          switch(type) {
              case 'edit':
                  await this.$router.push({
                      name: 'admin-communities-edit',
                      params: {
                          'uuid': uuid,
                      }
                  });
                  break;
          }
      },
  }
};
</script>
