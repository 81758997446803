import axios from '@/axios.js'

export default {
    getAppleAccounts: async (params) => {
        try {
            const response = await axios.adminapi.get(`v4/apps/apple-accounts`,{
                params: params
            })
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },

    deleteAppleAccount: async (uuid) => {
        const response = await axios.adminapi.delete(`v4/apps/apple-accounts/${uuid}`)
        return response.data.object
    },
}
