import axios from '@/axios.js'

export default {
    getApplications: async (params) => {
        try {
            const response = await axios.adminapi.get(`v4/apps`,{
                params: params
            })
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },

    getApplication: async (uuid) => {
        const response = await axios.adminapi.get(`v4/apps/${uuid}`)
        return response.data.object
    },

    createApplication: async (uuid, payload) => {
        const response = await axios.adminapi.post(`v4/apps/${uuid}`, payload)
        return response.data.object
    },

    updateApplication: async (uuid, payload) => {
        const response = await axios.adminapi.patch(`v4/apps/${uuid}`, payload)
        return response.data.object
    },

    getApplicationModules: async (uuid) => {
        try {
            const response = await axios.adminapi.get(`v4/apps/${uuid}/modules`)
            return response.data.object.data
        } catch (error) {
            throw new Error(error)
        }
    },

    createApplicationModule: async (applicationUuid, moduleId, data) => {
        try {
            const response = await axios.adminapi.post(`v4/apps/${applicationUuid}/modules/${moduleId}`, data)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },

    editApplicationModule: async (applicationUuid, moduleId, data) => {
        try {
            const response = await axios.adminapi.patch(`v4/apps/${applicationUuid}/modules/${moduleId}`, data)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },

    deleteApplicationModule: async (applicationUuid, moduleId) => {
        try {
            const response = await axios.adminapi.delete(`v4/apps/${applicationUuid}/modules/${moduleId}`)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },

    getAuthProviders: async (uuid) => {
        try {
            const response = await axios.adminapi.get(`v4/apps/${uuid}/auth-providers`)
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },

    updateAuthProviders: async (uuid, payload) => {
        try {
            const response = await axios.adminapi.patch(`v4/apps/${uuid}/auth-providers`, payload)
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },

    getPlatform: async (uuid, platform) => {
        try {
            const response = await axios.adminapi.get(`v4/apps/${uuid}/platforms/${platform}`)
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },

    updatePlatform: async (uuid, platform, payload) => {
        try {
            const response = await axios.adminapi.patch(`v4/apps/${uuid}/platforms/${platform}`, payload)
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },

    getResorts: async (uuid) => {
        try {
            const response = await axios.adminapi.get(`v4/apps/${uuid}/resorts`)
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },

    updateResorts: async (uuid, payload) => {
        try {
            const response = await axios.adminapi.patch(`v4/apps/${uuid}/resorts`, payload)
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },
}
