import PaginationService from '@/modules/Shared/Services/PaginationService'
import FacilitiesServices from '@/modules/Resorts/Services/FacilitiesServices'
import ZonesService from '@/modules/Resorts/Services/ZonesService'
import { getFacilityStatus, getFacilityDifficulty, getFacilityTimeOfYear } from "@/modules/Resorts/Helpers/facilityHelper"
import { getFacilityLiftType } from "@/modules/Resorts/Helpers/facilityHelper"
import { getFacilityGroomed } from "@/modules/Resorts/Helpers/facilityHelper"
import { getFormatInSelectFacilityStatus, getFormatInSelectFacilityLiftType, getFormatInSelectFacilityTimeOfYear } from "@/modules/Resorts/Helpers/facilityHelper"
import { getFormatInSelectFacilityDifficulty} from "@/modules/Resorts/Helpers/facilityHelper"
import { getFormatInSelectFacilityGroomed } from "@/modules/Resorts/Helpers/facilityHelper"

import { mapGetters } from 'vuex';
import { getParkingState, getParkingType, getRoadOptions } from '../../../Helpers/facilityHelper'

export default {
    data () {
        return {
            facilities: [],
            zones: [],
            datatableConfig: [],
            pagination: {},
            queryParams: {},
        }
    },
    props: {
        facilityDifficultyReferenceSystem: {type: String},
    },
    computed: {
        ...mapGetters( ['resortUuid']),
    },
    async created() {
        this.zones = (await ZonesService.getZones(this.resortUuid, {})).map((zone) => ({
            label: zone.name,
            value: zone.name
        }))

        this.getDataTableConfig()

        this.queryParams.page = 1
        this.queryParams.q = `type:${this.type}`

        await this.getFacilities( this.queryParams )

    },
    methods: {
        async fillTable() {
            let me = this
            this.facilities = this.facilities.data.map(function(facility) {
                return {
                    'uuid' : facility.uuid,
                    'name' : facility.name,
                    'code' : facility.code,
                    'type' : me.type,
                    'lift_type' : facility.lift_type ? getFormatInSelectFacilityLiftType(facility.lift_type) : undefined,
                    'status' : getFormatInSelectFacilityStatus(facility.state),
                    'timeofyear' : facility.timeofyear ? getFormatInSelectFacilityTimeOfYear(facility.timeofyear) : undefined,
                    'zone' : facility.zone ? facility.zone : undefined,
                    'length' : facility.longitude ? (me.referenceSystem === 'imperial' ? me.$options.filters.value_system_converter(facility.longitude, me.referenceSystem, 'distance'):facility.longitude) : undefined,
                    'difficulty' : facility.difficulty ? getFormatInSelectFacilityDifficulty(facility.difficulty, me.facilityDifficultyReferenceSystem) : undefined,
                    'groomed': facility.groomed ? getFormatInSelectFacilityGroomed(facility.groomed, facility.groomed_am, facility.groomed_pm) : undefined,
                    'parking_state': facility.parking_state,
                    'parking_type': facility.parking_type,
                    'road_state': facility.road_state,
                    'actions' : [ 'delete','editRow' ]
                }
            });
        },
        prepareDataTableConfig(fields) {
            const fieldConfigs = {
                name : { 'field' : 'name', head : this.$t("facilities.tableheader.name"), 'searchable' : true, 'sortable' : false, 'editable':true, class: 'min-w-200' },
                code : { 'field' : 'code', head : this.$t("facilities.tableheader.code"), 'searchable' : true, 'sortable' : false, 'editable':true, class: 'xs:hidden md:table-cell' },
                difficulty : { 'field' : 'difficulty', head : this.$t("facilities.tableheader.difficulty"), 'searchable' : true, 'searchableoptions': getFacilityDifficulty(this.facilityDifficultyReferenceSystem), 'options': getFacilityDifficulty(this.facilityDifficultyReferenceSystem), 'sortable' : false, 'editable':true, class: 'xs:hidden md:table-cell' },
                lift_type : { 'field' : 'lift_type', head : this.$t("facilities.tableheader.type"), 'searchable' : true, 'searchableoptions': getFacilityLiftType(), 'options': getFacilityLiftType(), 'sortable' : false, 'editable':true, class: 'xs:hidden md:table-cell' },
                groomed : { 'field' : 'groomed', head : this.$t("facilities.tableheader.groomed"), 'searchable' : true, 'searchableoptions': getFacilityGroomed(), 'options': getFacilityGroomed(), 'sortable' : false,'editable':true, class: 'xs:hidden md:table-cell' },
                status : { 'field' : 'status', head : this.$t("facilities.tableheader.status"), 'searchable' : true, 'searchableoptions':getFacilityStatus(), 'options':getFacilityStatus(), 'sortable' : false, 'editable':true },
                zone : { 'field' : 'zone', head : this.$t("facilities.tableheader.zone"), 'searchable' : true, 'searchableoptions': this.zones, 'options': this.zones, 'sortable' : false, 'editable':true, class: 'xs:hidden md:table-cell' },
                length : { 'field' : 'length', head : this.$t("facilities.tableheader.length") + " ("+ this.$options.filters.unit_system_converter('distance', this.referenceSystem)+")", 'searchable' : true, 'sortable' : false, 'editable':true, class: 'xs:hidden md:table-cell' },
                timeofyear : { 'field' : 'timeofyear', head : this.$t("facilities.tableheader.timeofyear"), 'searchable' : true, 'searchableoptions': getFacilityTimeOfYear(), 'options': getFacilityTimeOfYear(), 'sortable' : false, 'editable':true, class: 'xs:hidden md:table-cell' },
                actions : { 'field' : 'actions', 'type': 'actions', head : this.$t("facilities.tableheader.actions"), 'searchable' : false, 'sortable' : false },
                parking_type : { 'field' : 'parking_type', head : this.$t("facilities.tableheader.parking_type"), 'searchable' : false, 'sortable' : true, 'searchableoptions': getParkingType(), editable: true, options: getParkingType(), class: 'xs:hidden md:table-cell' },
                parking_state : { 'field' : 'parking_state', head : this.$t("facilities.tableheader.parking_status"), 'searchable' : false, 'sortable' : true, 'searchableoptions': getParkingState(), editable: true, options: getParkingState(), class: 'xs:hidden md:table-cell'  },
                road_state : { 'field' : 'road_state', head : this.$t("facilities.tableheader.road_state"), 'searchable' : false, 'sortable' : true, 'searchableoptions': getRoadOptions(), editable: true, options: getRoadOptions(), class: 'xs:hidden md:table-cell'  }
            }

            return fields.map((field) => fieldConfigs[field])
        },
        async getFacilities( params ) {
            this.facilities = await FacilitiesServices.getResortFacilities(this.resortUuid, params)
            this.pagination = {
                per_page: this.facilities.per_page,
                total: this.facilities.total
            }
            this.fillTable()
        },

        async saveAllFacilities(facilities) {
            let responseOk = false;
            let i = 1;
            let currentFacility = facilities[0];
            let validateValues = this.validateAndParseValues(currentFacility)

            do {
                if(validateValues.response) {
                    responseOk = await this.saveSingleFacility(currentFacility, validateValues.values)

                    currentFacility = facilities[i];
                    validateValues = this.validateAndParseValues(currentFacility)
                }
            i++;
            } while ( i <= facilities.length && responseOk)
            if(responseOk) {
                this.$vs.notify({text:this.$t("facilities.saved.ok"), color:'success',position:'top-right'})
                this.$refs.datatable.disableAllEditing();
            } else {
                this.$vs.notify({text:this.$t("facilities.saved.ko"), color:'danger',position:'top-right'})
            }
        },
        async saveSingleFacility(currentFacility, facilityValues) {
            try {
                const index = this.facilities.findIndex(facility => facility === currentFacility);
                if (facilityValues.uuid) {
                    const updatedFacility = await FacilitiesServices.updateResortFacility(this.resortUuid, facilityValues.uuid, facilityValues);
                    if( updatedFacility.code === 200) {
                        this.facilities[index].edited = 0;
                        return true
                    }
                } else {
                    const createdFacility =  await FacilitiesServices.createResortFacility(this.resortUuid, facilityValues);
                    if( createdFacility.data.code === 200) {
                        this.facilities[index].uuid = createdFacility.headers['content-location'];
                        this.facilities[index].edited = 0
                        return true
                    }
                }
                return false;
            } catch (e) {
                return false;
            }
        },

        async saveFacility(currentFacility,facilityValues) {
            try {
                this.$vs.loading();
                let response = await this.saveSingleFacility(currentFacility,facilityValues)

                if(response !== false) {
                    this.$vs.notify({text:this.$t("facility.updated.ok"), color:'success',position:'top-right'})
                } else {
                    this.$vs.notify({text:this.$t("facility.updated.ko"), color:'danger',position:'top-right'})
                }
                this.$vs.loading.close();
            } catch (error) {
                this.$vs.loading.close();
            }
        },

        validateAndParseValues(values) {
            let value;
            let parsedValues = {}
            parsedValues.type = this.type;
            for (const key in values) {
                switch (key) {
                    case 'name':
                        if(values[key].length === 0) {
                            const message = this.$t("field.cannot.be.empty")
                            this.$vs.notify({text:`${key} ${message}`, color:'danger',position:'top-right'})
                            return {response:false}
                        }else {
                            parsedValues[key] = values[key]

                        }
                    break;
                    case 'zone':
                        parsedValues[key] = values[key] ? values[key].value : null
                        break;
                    case 'code':
                    case 'uuid':
                        if (values[key]) {
                            parsedValues[key] = values[key]
                        }
                    break;
                    case 'length':
                        if (values[key]) {
                            parsedValues[key] = this.referenceSystem === 'imperial'?this.$options.filters.value_system_converter(values[key], 'metric', 'distance'):values[key]
                        }
                    break;
                    case 'timeofyear':
                    case 'difficulty':
                    case 'status':
                    case 'lift_type':
                        value = values[key] && values[key].value ? values[key].value : values[key]
                        if (value) {
                            parsedValues[key] = values[key].value ? values[key].value : values[key]
                        }
                    break;
                    case 'groomed':
                        parsedValues['groomed'] = 0
                        parsedValues['groomed_am'] = 0
                        parsedValues['groomed_pm'] = 0

                        if (values[key] !== null) {
                            switch(values[key].value) {
                                case 'groomed':
                                    parsedValues['groomed'] = 1
                                    parsedValues['groomed_am'] = 0
                                    parsedValues['groomed_pm'] = 0
                                break;
                                case 'not-groomed':
                                break;
                                case 'groomed-am':
                                    parsedValues['groomed'] = 0
                                    parsedValues['groomed_am'] = 1
                                    parsedValues['groomed_pm'] = 0
                                break;
                                case 'groomed-pm':
                                    parsedValues['groomed'] = 0
                                    parsedValues['groomed_am'] = 0
                                    parsedValues['groomed_pm'] = 1
                                break;
                            }
                        }
                    break;
                    case 'parking_type':
                        value = values[key] && values[key].value ? values[key].value : values[key]
                        if (value) {
                            parsedValues[key] = value
                        }
                    break;
                    case 'road_state':
                        value = values[key] && values[key].value ? values[key].value : values[key]
                        if(value){
                            parsedValues.road_state = value;
                            if(value === "equipment") parsedValues.status = "open"
                            else parsedValues.status = value
                        }
                    break;
                    case 'parking_state':
                        value = values[key] && values[key].value ? values[key].value : values[key]
                        if(value && value === "free"){
                            parsedValues.parking_state = value;
                            parsedValues.status = "open"
                        }
                        if(value && value === "close"){
                            parsedValues.parking_state = value;
                            parsedValues.status = "closed"
                        }
                    break;
                }
            }
            return {response:true, values:parsedValues}
        },

        async removeFacility(facilityToRemove) {

            const uuid = facilityToRemove.uuid
            let response = {}
            let isNewFacility = false
            if(uuid) {
                this.$vs.loading();
                response = await FacilitiesServices.deleteResortFacility(this.resortUuid, uuid);
                this.$vs.loading.close();
            } else {
                isNewFacility = true
            }



            if(response.code == 200 || isNewFacility) {
                this.facilities = this.facilities.filter((facility) => facility !== facilityToRemove);
                this.$vs.notify({text:this.$t("facility.removed.ok"), color:'success', position:'top-right'})
            } else {
                this.$vs.notify({text:this.$t("facility.removed.ko"), color:'danger', position:'top-right'})
            }
        },

        handleFilter(params) {
            this.getFacilities(PaginationService.parseParameters( {...this.queryParams,...params }))
        },

        handleAction(type, uuid, facility) {
            if( type === 'saveAllRows' ) {
                this.saveAllFacilities(this.facilities.filter(facility => facility.edited === 1))
            } else if( type === 'delete' ){
                this.removeFacility(facility)
            } else if (type === 'editRow') {
                let validateValues = this.validateAndParseValues(facility)
                if(validateValues.response) {
                    const index = this.facilities.findIndex(dataTableFacility => dataTableFacility === facility);

                    this.saveFacility(this.facilities[index],validateValues.values)
                    this.$refs.datatable.disableEditing(index);
                }
            }
        },
        handleAddRow() {
            this.facilities.push({
                'name' : '',
                'code' : '',
                'difficulty' : '',
                'status' : '',
                'zone' : '',
                'length' : '',
                'type' : this.type,
                'lift_type' : '',
                'timeofyear' : '',
                'groomed': '',
                'actions' : [ 'delete','editRow' ],
                'parking_state': '',
                'parking_type': '',
                'road_state': '',
            })
        },
    }
}
