<template>
  <tr v-if="hasSearchList" class="tr-values vs-table--tr tr-table-state-null">
    <td v-if="draggable"></td>
    <td v-for="(searching, index) in searchList" :key="index" :class="searching.class ? searching.class : ''">
      <data-table-search-cell
        ref="searchcell"
        :config="searching"
        @search="(b,c) => $emit('search',b,c)"
        @filter="() => $emit('filter')"
      />
    </td>
  </tr>
</template>
<script>
import DataTableSearchCell from '@/modules/Shared/Components/table/SKDataTableSearchCell.vue'

export default {
  name: 'data-table-search',
  components: {
    DataTableSearchCell,
  },
  props: {
    draggable: {type:Boolean, default: false},
    searchList: Array ,
  },
  computed: {
    hasSearchList() {
      return this.searchList.find(element => { return element.value === true}) !== undefined;
    }
  }
}
</script>
