import notifications from "@/modules/Shared/Mixins/notifications.js";

let openLoaders = 0;

const loader = {
	mixins: [notifications],
	methods: {
		async load(func) {
			if (openLoaders === 0) {
				this.$vs.loading();
			}
			openLoaders++;
			await func()
			openLoaders--;
			if (openLoaders === 0) {
				this.$vs.loading.close();
			}
		},

		async loadAndNotify(action, onlyError = false) {
			try {
				await this.load(action)
				if (!onlyError) {
					this.notifySuccess()
				}
				return true
			} catch (e) {
				this.$vs.loading.close();
				console.error(e)
				this.notifyError()
			}
			return false
		},

		async loadAndNotifyError(action) {
			return this.loadAndNotify(action, true)
		}
	}
}

export default loader
