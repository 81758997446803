import axios from '@/axios.js'

export default {
    getPhotos: async (params, page = 1) => {
        let response = await axios.adminapi.post(`v4/photos`, {
                page: page,
                ...params,
        })
        return response.data.object
    },
    editPhoto: async (uuid, data) => {
        await axios.publicapi.patch(`v4/photos/${uuid}`, data, { headers: { 'application-token': process.env.VUE_APP_PUBLIC_API_APP_TOKEN }})
    }
}
