import axios from '@/axios.js'

export default {
    getChallenges: async (params, page = 1) => {
        let response = await axios.adminapi.get(`v4/challenges`,{
            params: {
                page: page,
                ...params,
            }
        })
        return response.data.object
    },
    cloneChallenge: async (uuid, newUuid) => {
        let response = await axios.adminapi.post(`v4/challenges/${newUuid}`, { uuid: uuid })
        return response.data.object
    },

    saveChallenge: async (uuid, data) => {
        let response = await axios.adminapi.patch(`v4/challenges/${uuid}`, data)
        return response.data.object
    },

    createChallenge: async (uuid, data) => {
        let response = await axios.adminapi.post(`v4/challenges/${uuid}`, data)
        return response.data.object
    },

    deleteChallenge: async (uuid) => {
        let response = await axios.adminapi.delete(`v4/challenges/${uuid}`)
        return response.data
    },

    getChallenge: async (uuid) => {
        let response = await axios.adminapi.get(`v4/challenges/${uuid}`)
        return response.data.object
    },

    getChallengeUsers: async (uuid) => {
        let response = await axios.adminapi.get(`v4/challenges/${uuid}/users`)
        return response.data.object
    },

    getChallengeTranslations: async (uuid) => {
        let response = await axios.adminapi.get(`v4/challenges/${uuid}/translations`)
        return response.data.object.data
    },

    saveChallengeTranslation: async (uuid, language, data) => {
        let response = await axios.adminapi.patch(`v4/challenges/${uuid}/translations/${language}`, data)
        return response.data
    },

    createChallengeTranslation: async (uuid, language, data) => {
        let response = await axios.adminapi.post(`v4/challenges/${uuid}/translations/${language}`, data)
        return response.data
    },

    deleteChallengeTranslation: async (uuid, language) => {
        let response = await axios.adminapi.delete(`v4/challenges/${uuid}/translations/${language}`)
        return response.data
    },

    getChallengeRanking: async (uuid, language, locale, timezone, referenceSystem) => {
        let response = await axios.publicapi.get(`v4/challenges/${uuid}/standings?language=${language}&locale=${locale}&timezone=${timezone}&referenceSystem=${referenceSystem}`)
        return response.data.object
    },
}
